import React, {useEffect} from 'react';
import {toast} from 'react-toastify';
import {Controller, useForm} from 'react-hook-form';
import {
    FormControl,
    FormControlLabel,
    Switch,
    ThemeProvider,
} from '@mui/material';
import {
    apiValidationErrorResponse,
    StatusEnum,
    User,
} from '../../../redux/types';
import {useUpdateUserMutation} from '../../../redux/services/userApi';
import ModalBasic from '../../../components/Elements/ModalBasic';
import MUITheme from '../../../components/Theme/MUITheme';

type Props = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    user?: User;
};
const EditUserModal: React.FC<Props> = ({modalOpen, setModalOpen, user}) => {
    const [updateUser] = useUpdateUserMutation();
    const defaultValues = {
        status: user?.status ?? StatusEnum.on,
    };

    const form = useForm<User>({
        defaultValues: defaultValues,
    });
    const {control, reset} = form;

    useEffect(() => {
        reset(defaultValues);
    }, [user]);

    const onSubmit = async (data: User) => {
        if (!user) return;
        await updateUser({...data, id: user.id})
            .unwrap()
            .then(() => {
                toast.success('Пользователь успешно изменен.');
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<User>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    return (
        <>
            <ModalBasic
                id="basic-modal"
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                title={'Редактирование пользователя ' + user?.username}>
                {/* Modal content */}
                <div className="px-5 pt-4 pb-1">
                    {user && (
                        <Controller
                            control={control}
                            name="status"
                            render={({field}) => (
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl variant="standard">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        field.value === 'on'
                                                    }
                                                    onChange={event =>
                                                        field.onChange(
                                                            event.target.checked
                                                                ? 'on'
                                                                : 'off',
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                field.value === 'on'
                                                    ? 'Активен'
                                                    : 'Заблокирован'
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            )}
                        />
                    )}
                </div>

                {/* Modal footer */}
                <div className="px-5 py-4">
                    <div className="flex flex-wrap justify-end space-x-2">
                        <button
                            className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                            onClick={e => {
                                e.stopPropagation();
                                setModalOpen(false);
                            }}>
                            Отмена
                        </button>
                        <button
                            className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                            onClick={e => {
                                form.clearErrors();
                                form.handleSubmit(onSubmit)(e);
                            }}>
                            Сохранить
                        </button>
                    </div>
                </div>
            </ModalBasic>
        </>
    );
};
export default EditUserModal;
