import React from 'react';
import {Quiz} from '../../../redux/types';
import {UseFormReturn} from 'react-hook-form/dist/types';
import {Controller} from 'react-hook-form';
import {
    FormControl,
    FormControlLabel,
    Switch,
    TextField,
    ThemeProvider,
} from '@mui/material';
import MUITheme from '../../Theme/MUITheme';
import ImageForm from '../../Elements/ImageForm';
import {useAuth} from '../../../redux/hooks/useAuth';

type Props = {
    quiz: Quiz;
    form: UseFormReturn<Quiz, object>;
};

const SettingsForm: React.FC<Props> = ({quiz, form}) => {
    const {
        formState: {errors},
        control,
    } = form;

    const {user} = useAuth();

    const quizStatus = form.watch('status', quiz?.status);

    return (
        <div className="flex flex-row">
            <div className="basis-1/3 pr-4">
                <div className="mb-4">
                    <Controller
                        control={control}
                        name="title"
                        // defaultValue={quiz?.title ?? ''}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Название"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>

                {user?.isAdmin && (
                    <Controller
                        control={control}
                        name="prevent_changes"
                        render={({field}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl variant="standard">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(field.value)}
                                                {...field}
                                                color="error"
                                            />
                                        }
                                        label={
                                            Boolean(field.value)
                                                ? 'Изменение запрещено'
                                                : 'Изменение разрешено'
                                        }
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                )}

                <div
                    id="status-cover"
                    className={
                        quizStatus === 'on'
                            ? 'mb-4'
                            : 'rounded p-2 bg-light-blue-50 mb-4'
                    }>
                    <div className="">
                        <Controller
                            control={control}
                            name="status"
                            // defaultValue={quiz?.status ?? 'on'}
                            render={({field}) => (
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl variant="standard">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        field.value === 'on'
                                                    }
                                                    onChange={event =>
                                                        field.onChange(
                                                            event.target.checked
                                                                ? 'on'
                                                                : 'off',
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                field.value === 'on'
                                                    ? 'Включен'
                                                    : 'Выключен'
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            )}
                        />
                    </div>

                    <div
                        id="cover"
                        className={quizStatus === 'on' ? 'hidden' : 'visible'}>
                        <div className="mb-4">
                            <Controller
                                control={control}
                                name="cover_title"
                                // defaultValue={quiz?.cover_title ?? ''}
                                render={({field, fieldState}) => (
                                    <ThemeProvider theme={MUITheme}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth={true}>
                                            <TextField
                                                {...field}
                                                error={Boolean(
                                                    fieldState.error,
                                                )}
                                                label="Заголовок обложки"
                                                variant="standard"
                                                size="small"
                                                inputRef={field.ref}
                                                helperText={
                                                    fieldState.error?.message
                                                }
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                )}
                            />
                        </div>

                        <div className="mb-4">
                            <Controller
                                control={control}
                                name="cover_subtitle"
                                // defaultValue={quiz?.cover_subtitle ?? ''}
                                render={({field, fieldState}) => (
                                    <ThemeProvider theme={MUITheme}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth={true}>
                                            <TextField
                                                {...field}
                                                error={Boolean(
                                                    fieldState.error,
                                                )}
                                                label="Подзаголовок обложки"
                                                variant="standard"
                                                size="small"
                                                inputRef={field.ref}
                                                helperText={
                                                    fieldState.error?.message
                                                }
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                )}
                            />
                        </div>

                        <div className="mb-4">
                            <Controller
                                control={control}
                                name="cover_text"
                                // defaultValue={quiz?.cover_text ?? ''}
                                render={({field, fieldState}) => (
                                    <div className="mt-2">
                                        <ThemeProvider theme={MUITheme}>
                                            <FormControl
                                                variant="standard"
                                                fullWidth={true}>
                                                <TextField
                                                    multiline
                                                    rows="4"
                                                    {...field}
                                                    error={Boolean(
                                                        fieldState.error,
                                                    )}
                                                    label="Текст на обложке"
                                                    variant="standard"
                                                    size="small"
                                                    inputRef={field.ref}
                                                    helperText={
                                                        fieldState.error
                                                            ?.message
                                                    }
                                                />
                                            </FormControl>
                                        </ThemeProvider>
                                    </div>
                                )}
                            />
                        </div>

                        <div className="mb-4">
                            <Controller
                                control={control}
                                name="cover_images"
                                // defaultValue={quiz?.cover_images}
                                render={({field, fieldState}) => (
                                    <div>
                                        <div className="text-xs">
                                            Изображение
                                        </div>
                                        <ImageForm
                                            ownerId={quiz.id}
                                            {...field}
                                            {...fieldState}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <Controller
                        control={control}
                        name="url_id"
                        // defaultValue={quiz?.url_id ?? ''}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="URL"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>

                <div className="mb-4">
                    <Controller
                        control={control}
                        name="domain"
                        // defaultValue={quiz?.domain ?? ''}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Домен"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                                <div className="text-xs text-gray-500">
                                    Например, quiz.yandex.ru
                                </div>
                            </ThemeProvider>
                        )}
                    />
                </div>
            </div>
            <div className="basis-1/3 px-4">
                <div className="mb-4 hidden">
                    <Controller
                        control={control}
                        name="shuffle_questions"
                        // defaultValue={quiz?.shuffle_questions}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl variant="standard">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                defaultChecked={Boolean(
                                                    quiz?.shuffle_questions,
                                                )}
                                                {...field}
                                            />
                                        }
                                        label="Перемешивать вопросы"
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>

                <div className="mb-4">
                    <Controller
                        control={control}
                        name="tenacious_sessions"
                        // defaultValue={quiz?.shuffle_questions}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl variant="standard">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(field.value)}
                                                {...field}
                                            />
                                        }
                                        label="Сохранять статус прохождения после обновления страницы"
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>

                <div className="mb-4">
                    <Controller
                        control={control}
                        name="allowed_emails"
                        // defaultValue={quiz?.allowed_emails ?? ''}
                        render={({field, fieldState}) => (
                            <div className="mt-2">
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth={true}>
                                        <TextField
                                            multiline
                                            rows="4"
                                            {...field}
                                            error={Boolean(fieldState.error)}
                                            label="Разрешенные адреса Email"
                                            variant="standard"
                                            size="small"
                                            inputRef={field.ref}
                                            helperText={
                                                fieldState.error?.message
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            </div>
                        )}
                    />
                </div>

                <div className="mb-4">
                    <Controller
                        control={control}
                        name="prohibited_phones"
                        // defaultValue={quiz?.prohibited_phones ?? ''}
                        render={({field, fieldState}) => (
                            <div className="mt-2">
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth={true}>
                                        <TextField
                                            multiline
                                            rows="4"
                                            {...field}
                                            error={Boolean(fieldState.error)}
                                            label="Запрещенные номера телефонов"
                                            variant="standard"
                                            size="small"
                                            inputRef={field.ref}
                                            helperText={
                                                fieldState.error?.message ??
                                                'формат номеров: "79039999999"'
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="basis-1/3">
                <div className="mb-4">
                    <Controller
                        control={control}
                        name="notice_emails"
                        // defaultValue={quiz?.notice_emails ?? ''}
                        render={({field, fieldState}) => (
                            <div className="mt-2">
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth={true}>
                                        <TextField
                                            multiline
                                            rows="4"
                                            {...field}
                                            error={Boolean(fieldState.error)}
                                            label="Email адреса для уведомлений о прохождениях"
                                            variant="standard"
                                            size="small"
                                            inputRef={field.ref}
                                            helperText={
                                                fieldState.error?.message ??
                                                'через запятую'
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            </div>
                        )}
                    />
                </div>

                {user && quiz.owner_id === user.id ? (
                    <div className="mb-4">
                        <Controller
                            control={control}
                            name="moderators_emails"
                            // defaultValue={quiz?.moderators_emails ?? ''}
                            render={({field, fieldState}) => (
                                <div className="mt-2">
                                    <ThemeProvider theme={MUITheme}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth={true}>
                                            <TextField
                                                multiline
                                                rows="4"
                                                {...field}
                                                error={Boolean(
                                                    fieldState.error,
                                                )}
                                                label="Email адреса модераторов"
                                                variant="standard"
                                                size="small"
                                                inputRef={field.ref}
                                                helperText={
                                                    fieldState.error?.message
                                                }
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                </div>
                            )}
                        />
                    </div>
                ) : null}

                <div className="mb-4 hidden">
                    <Controller
                        control={control}
                        name="quiz_time_limit"
                        // defaultValue={quiz?.quiz_time_limit}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Время на прохождение квиза"
                                        variant="standard"
                                        type="time"
                                        inputProps={{step: 1}}
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>

                <div className="mb-4 hidden">
                    <Controller
                        control={control}
                        name="question_time_limit"
                        // defaultValue={quiz?.question_time_limit}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Время на ответ"
                                        variant="standard"
                                        type="time"
                                        inputProps={{step: 1}}
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
export default SettingsForm;
