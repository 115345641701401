import {createApi} from '@reduxjs/toolkit/query/react';
import {User} from '../types';
import {baseQueryWithReauth} from './baseQueryWithReauth';

export interface UserResponse {
    user: User;
    token: string;
}

export interface LoginRequest {
    username: string;
    password: string;
}

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        login: builder.mutation<UserResponse, LoginRequest>({
            query: credentials => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials,
                credentials: 'include',
            }),
        }),
    }),
});

export const {useLoginMutation} = authApi;
