import React from 'react';
import {Control, Controller, UseFormWatch} from 'react-hook-form';
import {Delete, Settings} from '@mui/icons-material';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
} from '@mui/material';
import {Question, Connection, ConnectionCondition} from '../../../redux/types';
import MUITheme from '../../Theme/MUITheme';
import {
    RELATION_CONDITION_SIGNS,
    RELATION_CONDITION_TYPES,
} from '../../../constants';

type Props = {
    remove: (index: number) => void;
    index: number;
    field: ConnectionCondition;
    control: Control<Connection>;
    questions: Question[];
    watch: UseFormWatch<Connection>;
};

const ConnectionConditionForm: React.FC<Props> = ({
    remove,
    index,
    field,
    control,
    questions,
    watch,
}) => {
    const watchType = watch('connectionConditions')?.[index].type;
    const questionId = watch('connectionConditions')?.[index].question_id;
    const selectedQuestion = questions.find(q => q.id === questionId);

    return (
        <div className="flex flex-row" key={field.id}>
            <div className="basis-2/12 mb-4 pr-1">
                <Controller
                    control={control}
                    name={`connectionConditions.${index}.type`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" className="w-full">
                                <InputLabel>Тип</InputLabel>
                                <Select
                                    {...field}
                                    value={field.value ?? ''}
                                    size="small"
                                    inputRef={field.ref}>
                                    {RELATION_CONDITION_TYPES.map(type => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            <div className="basis-5/12 mb-4 pr-1">
                <Controller
                    control={control}
                    name={`connectionConditions.${index}.question_id`}
                    defaultValue=""
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl
                                variant="standard"
                                fullWidth={true}
                                error={Boolean(fieldState.error)}>
                                <InputLabel>Вопрос</InputLabel>
                                <Select
                                    {...field}
                                    size="small"
                                    value={field.value ?? ''}
                                    inputRef={field.ref}>
                                    {questions.map(q => (
                                        <MenuItem key={q.id} value={q.id}>
                                            {q.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {fieldState.error?.message}
                                </FormHelperText>
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            <div
                className={'basis-4/12 mb-4 pr-1'}
                hidden={watchType !== 'answer_option'}>
                <Controller
                    control={control}
                    name={`connectionConditions.${index}.answer_option_id`}
                    defaultValue=""
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl
                                variant="standard"
                                fullWidth={true}
                                error={Boolean(fieldState.error)}>
                                <InputLabel>Вариант ответа</InputLabel>
                                <Select
                                    {...field}
                                    size="small"
                                    value={field.value ?? ''}
                                    inputRef={field.ref}>
                                    {selectedQuestion?.answerOptions?.map(q => (
                                        <MenuItem key={q.id} value={q.id}>
                                            {q.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {fieldState.error?.message}
                                </FormHelperText>
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            <div
                className="basis-2/12 mb-4 pr-1"
                hidden={watchType !== 'points'}>
                <Controller
                    control={control}
                    name={`connectionConditions.${index}.sign`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl
                                variant="standard"
                                fullWidth={true}
                                error={Boolean(fieldState.error)}>
                                <InputLabel></InputLabel>
                                <Select
                                    {...field}
                                    size="small"
                                    value={field.value ?? ''}
                                    inputRef={field.ref}>
                                    {RELATION_CONDITION_SIGNS.map(type => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {fieldState.error?.message}
                                </FormHelperText>
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            <div className="basis-2/12" hidden={watchType !== 'points'}>
                <Controller
                    control={control}
                    name={`connectionConditions.${index}.value`}
                    defaultValue={0}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="Баллы"
                                    variant="standard"
                                    size="small"
                                    value={field.value ?? ''}
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            <div className="basis-1/12 pl-1 pt-4">
                <button className="align-middle" onClick={() => remove(index)}>
                    <Delete />
                </button>
            </div>
        </div>
    );
};

export default ConnectionConditionForm;
