import React, {useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {FormControl, TextField, ThemeProvider} from '@mui/material';
import {
    apiValidationErrorResponse,
    Question,
    StatusEnum,
} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import MUITheme from '../../Theme/MUITheme';
import ImagesForm from '../../Elements/ImagesForm';
import AnswerOptionForm from './QuestionBlock/AnswerOptionForm';
import {useUpdateQuestionMutation} from '../../../redux/services/quizApi';
import VisualEditor from '../../VisualEditor/VisualEditor';

type Props = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    question: Question;
};
const EditQuestionModal: React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    question,
}) => {
    const [updateQuestion] = useUpdateQuestionMutation();
    const defaultValues = {
        title: question.title ?? '',
        subtitle: question.subtitle ?? '',
        description: question.description ?? '',
        video_url: question.video_url ?? '',
        images: question.images ?? [],
        answerOptions: question.answerOptions ?? [],
        correct_answer_points: question.correct_answer_points ?? '',
        time_limit: question.time_limit ?? '00:00:00',
    };
    const form = useForm<Question>({
        defaultValues: defaultValues,
    });
    useEffect(() => {
        form.reset(defaultValues);
        remove();
        question.answerOptions && append(question.answerOptions);
    }, [question]);

    const {control} = form;
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'answerOptions',
        keyName: 'key',
    });

    const addAOField = () => {
        const newAnswerOption = {
            id: nanoid(10),
            question_id: question.id,
            title: '',
            points: 0,
            order: fields.length++,
            is_right: false,
            onclick_action: '',
            status: 'on' as StatusEnum,
        };
        append(newAnswerOption);
    };

    const onSubmit = async (data: Question) => {
        // console.log({...data, id: question.id});
        await updateQuestion({...data, id: question.id})
            .unwrap()
            .then(() => {
                toast.success('Вопрос успешно обновлен.');
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<Question>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    const answerOptionsBlock = () => {
        if (question.type === 'textarea') return null;

        return (
            <div className=" mt-4">
                {fields.map((field, index, reset) => (
                    <AnswerOptionForm
                        key={index}
                        remove={remove}
                        index={index}
                        field={field}
                        control={control}
                        question={question}
                    />
                ))}
                <div className="w-full text-right">
                    <button className="btn-xs" onClick={addAOField}>
                        + вариант ответа
                    </button>
                </div>
            </div>
        );
    };

    const modalSubtitle = () => {
        switch (question.type) {
            case 'checkbox':
                return 'с множественным выбором';
            case 'radiobutton':
                return 'с единичным выбором';
            case 'textarea':
                return 'со свободным вводом';
            default:
                return '';
        }
    };

    return (
        <ModalBasic
            id="basic-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title="Редактирование вопроса"
            subtitle={modalSubtitle()}>
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
                <Controller
                    control={control}
                    name="title"
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    multiline={true}
                                    rows={2}
                                    error={Boolean(fieldState.error)}
                                    label="Текст вопроса"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
                {answerOptionsBlock()}
                <Controller
                    control={control}
                    name="images"
                    render={({field, fieldState}) => (
                        <div className="mt-5">
                            <ImagesForm
                                ownerId={question.id}
                                {...field}
                                {...fieldState}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name="subtitle"
                    render={({field, fieldState}) => (
                        <div className="mt-2">
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        multiline={true}
                                        rows={2}
                                        error={Boolean(fieldState.error)}
                                        label="Подзаголовок"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="description"
                    render={({field, fieldState}) => (
                        <div className="mt-2">
                            <VisualEditor
                                name={field.name}
                                onChange={field.onChange}
                                value={field.value}
                            />
                            {/*<ThemeProvider theme={MUITheme}>*/}
                            {/*    <FormControl*/}
                            {/*        variant="standard"*/}
                            {/*        fullWidth={true}>*/}
                            {/*        <TextField*/}
                            {/*            {...field}*/}
                            {/*            multiline={true}*/}
                            {/*            rows={4}*/}
                            {/*            error={Boolean(fieldState.error)}*/}
                            {/*            label="Подзаголовок"*/}
                            {/*            variant="standard"*/}
                            {/*            size="small"*/}
                            {/*            inputRef={field.ref}*/}
                            {/*            helperText={fieldState.error?.message}*/}
                            {/*        />*/}
                            {/*    </FormControl>*/}
                            {/*</ThemeProvider>*/}
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="time_limit"
                    render={({field, fieldState}) => (
                        <div className="mt-2 hidden">
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Время на ответ"
                                        variant="standard"
                                        type="time"
                                        inputProps={{step: 1}}
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="video_url"
                    render={({field, fieldState}) => (
                        <div className="mt-2">
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Код отображения видео"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                            <div className="text-xs">
                                Необходима обложка - минимум одно изображение
                            </div>
                        </div>
                    )}
                />
                {question.type === 'checkbox' && (
                    <Controller
                        control={control}
                        name="correct_answer_points"
                        render={({field, fieldState}) => (
                            <div className="mt-2">
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth={true}>
                                        <TextField
                                            {...field}
                                            error={Boolean(fieldState.error)}
                                            label="Баллы при правильном ответе"
                                            variant="standard"
                                            size="small"
                                            inputRef={field.ref}
                                            helperText={
                                                fieldState.error?.message
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            </div>
                        )}
                    />
                )}
            </div>

            {/* Modal footer */}
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={e => {
                            form.clearErrors();
                            form.handleSubmit(onSubmit)(e);
                        }}>
                        Сохранить
                    </button>
                </div>
            </div>
        </ModalBasic>
    );
};
export default EditQuestionModal;
