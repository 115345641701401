import React, {useEffect, useState} from 'react';
import {
    Form,
    Question,
    Quiz,
    Connection,
    Result,
    Page,
} from '../../../../../redux/types';
import Warning from './Warning';

type Props = {
    quiz: Quiz;
    questions: Question[];
    results: Result[];
    connections: Connection[];
    forms: Form[];
    pages: Page[];
};

const WarningsBlock: React.FC<Props> = React.forwardRef((props, ref) => {
    const [warnings, setWarnings] = useState<string[]>([]);
    const unattainableElements = [];
    const doubleZeroCondConnections = [];
    const withoutZeroCondConnections = [];
    let notFinishedChains = false;

    useEffect(() => {
        const warns = [];
        calcUnattainableElements();
        if (unattainableElements.length > 0)
            warns.push(
                'Присутствуют недосягаемые элементы (' +
                    unattainableElements.length +
                    ').',
            );

        calcDoubleZeroCondConnections();
        if (doubleZeroCondConnections.length > 0)
            warns.push(
                'Присутствуют дубли связей без условий (' +
                    doubleZeroCondConnections.length +
                    ').',
            );

        calcWithoutZeroCondConnections();
        if (
            withoutZeroCondConnections.length > 0 &&
            props.questions.length + props.forms.length > 1
        )
            warns.push(
                'Присутствуют вопросы или формы, у которых нет исходящих связей без условий (' +
                    withoutZeroCondConnections.length +
                    ').',
            );

        calcNotFinishedChains();
        if (notFinishedChains)
            warns.push('Присутствуют цепочки, не оканчивающиеся результатами.');
        setWarnings(warns);
    }, [
        props.results,
        props.connections,
        props.questions,
        props.forms,
        props.quiz.first_element_id,
    ]);

    const setAttainableElements = (
        connections: Connection[],
        attainable: string[],
    ) => {
        connections.map(connection => {
            attainable.push(connection.target_obj_id);
            setAttainableElements(
                props.connections.filter(
                    filConnection =>
                        connection.target_obj_id ===
                            filConnection.source_obj_id &&
                        !attainable.includes(filConnection.target_obj_id),
                ),
                attainable,
            );
        });
    };

    const calcWithoutZeroCondConnections = () => {
        const arr = props.questions.concat(props.forms as []);
        arr.map(ele => {
            let hasCon = false;
            let hasZeroCon = false;
            props.connections.map(con => {
                if (con.source_obj_id === ele.id) {
                    hasCon = true;
                    if (con.connectionConditions?.length === 0) {
                        hasZeroCon = true;
                    }
                }
            });
            hasCon && !hasZeroCon && withoutZeroCondConnections.push(ele);
        });
    };

    const calcNotFinishedChains = () => {
        props.questions.map(ele => {
            const hasUpcoming = props.connections.find(
                connection => connection.source_obj_id === ele.id,
            );
            if (!hasUpcoming) notFinishedChains = true;
        });

        if (!notFinishedChains) {
            props.forms.map(ele => {
                const hasUpcoming = props.connections.find(
                    connection => connection.source_obj_id === ele.id,
                );
                if (!hasUpcoming) notFinishedChains = true;
            });
        }
    };

    const calcDoubleZeroCondConnections = () => {
        const zeroCondConnection = new Map();
        let key;
        props.connections.map(connection => {
            if (connection.connectionConditions?.length === 0) {
                key = connection.source_obj_id;
                if (zeroCondConnection.has(key))
                    doubleZeroCondConnections.push(connection);
                else zeroCondConnection.set(key, 1);
            }
        });
    };

    const calcUnattainableElements = () => {
        const attainable: string[] = props.quiz.first_element_id
            ? [props.quiz.first_element_id]
            : [];
        setAttainableElements(
            props.connections.filter(
                connection =>
                    connection.source_obj_id === props.quiz.first_element_id,
            ),
            attainable,
        );

        props.forms.map(
            e => !attainable.includes(e.id) && unattainableElements.push(e.id),
        );
        props.questions.map(
            e => !attainable.includes(e.id) && unattainableElements.push(e.id),
        );
        props.results.map(
            e => !attainable.includes(e.id) && unattainableElements.push(e.id),
        );
    };

    if (warnings.length < 1) return null;
    return (
        <div className="text-orange-600 border-dashed rounded border text-sm border-orange-400 p-2 mb-2">
            {warnings.map((w, index) => (
                <Warning key={index} warning={w} />
            ))}
        </div>
    );
});

export default WarningsBlock;
