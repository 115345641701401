export type Quiz = {
    id: string;
    title: string;
    url_id: string;
    domain: string;
    shuffle_questions: boolean;
    tenacious_sessions: boolean;
    status: 'on' | 'off' | 'deleted';
    questions?: Question[];
    first_element_id: string | null;
    prohibited_phones: string;
    allowed_emails: string;
    extra_styles: string | null;
    styleParams?: StyleParam[];
    styles?: QuizStyle[];
    logotypes: Image[];
    cover_images: Image[];
    favicons: Image[];
    quiz_time_limit: string;
    question_time_limit: string;
    cover_title: string | null;
    cover_subtitle: string | null;
    cover_text: string | null;
    notice_emails: string;
    lead2call_link: string;
    lead2call_links?: Lead2CallLink[];
    comagic_site_id?: number;
    comagic_token?: string;
    ym_id?: string;
    vk_id?: string;
    vk_ads_id?: string;
    ga_id?: string;
    gtm_id?: string;
    tm_id?: string;
    calltouch_site_id?: string;
    calltouch_mod_id?: string;
    jivosite_id?: string;
    moderators_emails?: string;
    scb_links?: ScbLink[];
    owner_id: number;
    owner: User;
    prevent_changes: boolean;
};

export type Lead2CallLink = {
    id: string;
    quiz_id: string;
    utm_source: string;
    link: string;
};

export type ScbLink = {
    id: string;
    quiz_id: string;
    utm_source: string;
    link: string;
    client_token: string;
    signature: string;
    api_token: string;
    domen_id: number;
};

export type QuizStyle = {
    id?: number;
    quiz_id: string;
    prop: string;
    value: string;
};

export type StyleParam = {
    title: string;
    label: string;
    transform: string;
    type: string;
};

export type Image = {
    id?: string;
    dataURL?: string;
    type?: string | null;
};

export type Question = {
    id: string;
    quiz_id: string;
    title: string;
    order: number;
    type: 'radiobutton' | 'checkbox' | 'textarea';
    status: 'on' | 'off' | 'deleted';
    answerOptions?: AnswerOption[];
    images?: Image[];
    subtitle: string;
    description: string;
    video_url: string;
    correct_answer_points: number;
    time_limit: string;
};

export type Connection = {
    id: string;
    quiz_id: string;
    source_obj_id: string;
    target_obj_id: string;
    connectionConditions?: ConnectionCondition[];
};

export type User = {
    id: number;
    username: string;
    email: string;
    isAdmin: boolean;
    status: StatusEnum;
};

export type Result = {
    id: string;
    quiz_id: string;
    title: string;
    status: 'on' | 'off' | 'deleted';
    type: 'redirect' | 'image_description';
    subtitle: string;
    description: string;
    redirect_url: string;
    images?: Image[];
};

export type Form = {
    id: string;
    status: 'on' | 'off' | 'deleted';
    quiz_id: string;
    title: string;
    description: string;
    onsubmit_action: string;
    images?: Image[];
    show_name_field?: boolean;
    show_surname_field?: boolean;
    show_phone_field?: boolean;
    show_email_field?: boolean;
};

export type Page = {
    id: string;
    status: 'on' | 'off' | 'deleted';
    quiz_id: string;
    title: string;
    images?: Image[];
    description: string;
    button_text: string;
    is_modal: boolean;
};

export enum ConnectionConditionTypeEnum {
    'points' = 'points',
    'answer_option' = 'answer_option',
}

export enum ConnectionConditionSignEnum {
    '=' = '=',
    '>' = '>',
    '<' = '<',
}
export type ConnectionCondition = {
    id: string;
    connection_id: string;
    answer_option_id: string | null;
    question_id: string;
    type: ConnectionConditionTypeEnum;
    sign: ConnectionConditionSignEnum;
    value: number;
};

export enum StatusEnum {
    'on' = 'on',
    'off' = 'off',
    'deleted' = 'deleted',
}

export enum errorMessageActionEnum {
    'NEED_ACTION_LOGOUT' = 'NEED_ACTION_LOGOUT',
}
export type AnswerOption = {
    id: string;
    question_id: string;
    title: string;
    onclick_action: string;
    order: number;
    points: number;
    status: StatusEnum;
    is_right: boolean;
};

export type apiValidationError<T> = {
    field: keyof T;
    message: string;
};

export type apiValidationErrorResponse<T> = {
    data: apiValidationError<T>[];
    status: number;
};

export type UtmValues = {
    utm_source?: string | null;
    utm_campaign?: string | null;
    utm_term?: string | null;
    utm_content?: string | null;
    utm_medium?: string | null;
};

export type DateRange = {
    from: string | null;
    to: string | null;
};

export interface FunnelFilters extends UtmValues {
    dateRange: DateRange;
}

export type LeadReportFilters = {
    dateRange: DateRange;
};

export type FunnelActionData = {
    id: string;
    title: string;
    status: StatusEnum;
    type: string;
    count: number;
};

export type FunnelResultSessionsData = {
    result_id: string | null;
    result_title: string | null;
    count: number;
};

export type FunnelData = {
    sessions: {
        total: FunnelResultSessionsData[];
        abandoned: {count: number};
    };
    exit_points: [
        {
            id: string;
            count: number;
        },
    ];
    actions: FunnelActionData[];
    // tracks: []; включить для графиков
};

export type LeadReportData = {
    leads: {
        [key: string]: [
            {
                date: string;
                count: number;
            },
        ];
    };
};
