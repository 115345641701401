import {CytoscapeOptions, NodeDefinition} from 'cytoscape';
import {
    Form,
    Question,
    Quiz,
    Connection,
    Result,
    Page,
} from '../../../../redux/types';

export const prepareNodeLabel = (label: string) => {
    const wordsLimit = 3;
    const parts = label.split(' ');
    let ret = parts.slice(0, 3).join('\n');
    if (parts.length > wordsLimit) {
        return ret + '...';
    } else return ret;
};

export const cyOptions: CytoscapeOptions = {
    maxZoom: 3,
    minZoom: 0.3,
    // autoungrabify: true,

    style: [
        {
            selector: 'node',
            style: {
                color: 'rgba(30, 41, 59, 1)',
                'font-size': '0.3em',
                'background-color': '#d0e4f3',
                content: 'data(label)',
                'text-halign': 'center',
                'text-valign': 'center',
                'padding-left': '1',
                shape: 'round-rectangle',
                'overlay-color': 'green',
                'overlay-padding': 2,
            },
        },
        {
            selector: '.multiline-auto',
            style: {
                'text-wrap': 'wrap',
                'text-max-width': '40',
            },
        },
        {
            selector: 'edge',
            style: {
                width: 3,
                'line-color': 'rgb(226, 232, 240)',
                'target-arrow-color': 'rgb(226, 232, 240)',
                'target-arrow-shape': 'triangle',
                'curve-style': 'bezier',
            },
        },

        {
            selector: 'edge.withCond',
            style: {
                'line-color': 'rgb(165,189,218)',
                'target-arrow-color': 'rgb(165,189,218)',
            },
        },

        {
            selector: '.result',
            style: {
                shape: 'star',
            },
        },
        {
            selector: '.form',
            style: {
                shape: 'round-pentagon',
            },
        },
        {
            selector: '.page',
            style: {
                shape: 'round-heptagon',
            },
        },
        {
            selector: '.modal_window',
            style: {
                shape: 'round-diamond',
            },
        },
        {
            selector: '.question',
            style: {
                shape: 'round-rectangle',
            },
        },
        {
            selector: '.first',
            style: {
                'background-color': '#b6e5af',
            },
        },

        {
            selector: ':selected',
            style: {
                color: 'white',
                'border-width': '10',
                'border-color': 'orange',
                backgroundColor: 'rgb(99, 102, 241)',
                'target-arrow-color': 'rgb(99, 102, 241)',
                'line-color': 'rgb(99, 102, 241)',
            },
        },
    ],
    layout: {
        name: 'dagre',
        // rows: 1,
    },
};

export const generateElements = (
    quiz: Quiz,
    questions: Question[],
    results: Result[],
    connections: Connection[],
    forms: Form[],
    pages: Page[],
) => {
    const elements: NodeDefinition[] = [];
    const elementIds: string[] = [];
    questions.map((q, index) => {
        elements.push({
            data: {
                id: q.id,
                label: prepareNodeLabel(q.title),
            },
            classes:
                'multiline-auto question ' +
                (quiz.first_element_id === q.id ? 'first' : ''),
            scratch: {
                elementType: 'question',
            },
        });
        elementIds.push(q.id);
    });

    results.map((q, index) => {
        elements.push({
            data: {
                id: q.id,
                label: prepareNodeLabel(q.title),
            },
            classes: 'multiline-auto result',
            scratch: {
                elementType: 'result',
            },
        });
        elementIds.push(q.id);
    });

    pages.map((q, index) => {
        elements.push({
            data: {
                id: q.id,
                label: prepareNodeLabel(q.title),
            },
            classes:
                'multiline-auto ' +
                (quiz.first_element_id === q.id ? 'first ' : '') +
                (q.is_modal ? 'modal_window' : 'page'),
            scratch: {
                elementType: q.is_modal ? 'modal_window' : 'page',
            },
        });
        elementIds.push(q.id);
    });

    forms.map((q, index) => {
        elements.push({
            data: {
                id: q.id,
                label: prepareNodeLabel(q.title),
            },
            classes:
                'multiline-auto form ' +
                (quiz.first_element_id === q.id ? 'first' : ''),
            scratch: {
                elementType: 'form',
            },
        });
        elementIds.push(q.id);
    });

    connections.map(
        connection =>
            elementIds.indexOf(connection.source_obj_id) !== -1 &&
            elementIds.indexOf(connection.target_obj_id) !== -1 &&
            elements.push({
                data: {
                    id: connection.id,
                    source: connection.source_obj_id,
                    target: connection.target_obj_id,
                },
                classes:
                    connection.connectionConditions &&
                    connection.connectionConditions.length > 0
                        ? 'withCond'
                        : 'noCond',
                scratch: {
                    elementType: 'connection',
                },
            }),
    );

    return elements;
};
