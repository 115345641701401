import React, {useDeferredValue, useEffect, useState} from 'react';
import QuizzesList from '../components/QuizzesList';
import NewQuiz from '../components/QuizForms/NewQuiz';
import SearchForm from '../components/Elements/SearchForm';
import {useParams} from 'react-router-dom';
import Breadcrumbs from '../components/Elements/Breadcrumbs';

const HomePage: React.FC = () => {
    const {searchTerm: st} = useParams();
    const [searchTerm, setSearchTerm] = useState(st ?? '');
    const deferredSearchTerm = useDeferredValue(searchTerm);

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs breadcrumbs={[{title: 'Квизы'}]} />
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <SearchForm
                        placeholder="Поиск"
                        setTerm={setSearchTerm}
                        defaultValue={st}
                    />
                    <NewQuiz />
                </div>
            </div>

            <QuizzesList deferredSearchTerm={deferredSearchTerm} />
        </div>
    );
};

export default HomePage;
