import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Switch,
    TextField,
    ThemeProvider,
} from '@mui/material';
import {apiValidationErrorResponse, Form} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import MUITheme from '../../Theme/MUITheme';
import ImagesForm from '../../Elements/ImagesForm';
import {useUpdateFormMutation} from '../../../redux/services/quizApi';

type Props = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    formObj: Form;
};
const EditFormModal: React.FC<Props> = ({modalOpen, setModalOpen, formObj}) => {
    const [updateFormObj] = useUpdateFormMutation();
    const defaultValues = {
        title: formObj.title ?? '',
        description: formObj.description ?? '',
        onsubmit_action: formObj.onsubmit_action ?? '',
        images: formObj.images ?? [],
        show_name_field: formObj.show_name_field ?? false,
        show_surname_field: formObj.show_surname_field ?? false,
        show_phone_field: formObj.show_phone_field ?? true,
        show_email_field: formObj.show_email_field ?? false,
    };
    const form = useForm<Form>({
        defaultValues: defaultValues,
    });

    useEffect(() => {
        form.reset(defaultValues);
    }, [formObj]);

    const {control} = form;

    const onSubmit = async (data: Form) => {
        await updateFormObj({...data, id: formObj.id})
            .unwrap()
            .then(() => {
                toast.success('Форма успешно обновлена.');
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<Form>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    return (
        <ModalBasic
            id="basic-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title="Редактирование формы">
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
                <Controller
                    control={control}
                    name="title"
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    multiline={true}
                                    rows={2}
                                    error={Boolean(fieldState.error)}
                                    label="Заголок"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />

                <Controller
                    control={control}
                    name="images"
                    // defaultValue={question?.images}
                    render={({field, fieldState}) => (
                        <div className="mt-5">
                            <ImagesForm
                                ownerId={formObj.id}
                                {...field}
                                {...fieldState}
                            />
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="description"
                    // defaultValue={question?.description ?? ''}
                    render={({field, fieldState}) => (
                        <div className="mt-2">
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        multiline
                                        rows="4"
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Описание"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="onsubmit_action"
                    // defaultValue={question?.title}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    multiline
                                    rows="4"
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="JS действие после отправки формы"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />

                <div className="mt-3 bg-gray-100 text-sm p-2 rounded">
                    Показывать поля
                    <div className="flex flex-row">
                        <div className="basis-1/4">
                            <Controller
                                control={control}
                                name="show_name_field"
                                // defaultValue={formObj?.show_surname_field}
                                render={({field, fieldState}) => (
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked={
                                                formObj?.show_name_field ??
                                                false
                                            }
                                            onChange={field.onChange}
                                        />
                                        <span className="pl-1">Имя</span>
                                    </label>
                                )}
                            />
                        </div>

                        <div className="basis-1/4">
                            <Controller
                                control={control}
                                name="show_surname_field"
                                // defaultValue={formObj?.show_surname_field}
                                render={({field, fieldState}) => (
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked={
                                                formObj?.show_surname_field ??
                                                false
                                            }
                                            onChange={field.onChange}
                                        />
                                        <span className="pl-1">Фамилия</span>
                                    </label>
                                )}
                            />
                        </div>

                        <div className="basis-1/4">
                            <Controller
                                control={control}
                                name="show_phone_field"
                                // defaultValue={formObj?.show_surname_field}
                                render={({field, fieldState}) => (
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked={
                                                formObj?.show_phone_field ??
                                                false
                                            }
                                            onChange={field.onChange}
                                        />
                                        <span className="pl-1">Телефон</span>
                                    </label>
                                )}
                            />
                        </div>

                        <div className="basis-1/4">
                            <Controller
                                control={control}
                                name="show_email_field"
                                // defaultValue={formObj?.show_surname_field}
                                render={({field, fieldState}) => (
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked={
                                                formObj?.show_email_field ??
                                                false
                                            }
                                            onChange={field.onChange}
                                        />
                                        <span className="pl-1">Email</span>
                                    </label>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal footer */}
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={form.handleSubmit(onSubmit)}>
                        Сохранить
                    </button>
                </div>
            </div>
        </ModalBasic>
    );
};
export default EditFormModal;
