import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {
    AnswerOption,
    Form,
    Question,
    Quiz,
    Connection,
    Result,
    Page,
} from '../types';
import {baseQueryWithReauth} from './baseQueryWithReauth';
import {BaseQueryMeta} from '@reduxjs/toolkit/dist/query/baseQueryTypes';

type QuizListArgs = {
    page: number;
    searchTerm: string;
};
export const quizApi = createApi({
    reducerPath: 'quizApi',
    tagTypes: [
        'Quizzes',
        'Questions',
        'Results',
        'Connections',
        'AnswerOptions',
        'Forms',
        'Pages',
    ],
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getQuizzes: builder.query<
            {
                items: Quiz[];
                totalCount: number;
                currentPage: number;
                pageCount: number;
                perPage: number;
            },
            QuizListArgs
        >({
            query: ({page = 1, searchTerm = ''}) =>
                `admin/quizzes?sort=title&page=${page}&searchTerm=${searchTerm}&debug=1`,
            transformResponse(response: Quiz[], meta: BaseQueryMeta<any>) {
                return {
                    items: response,
                    totalCount: Number(
                        meta.response.headers.get('x-pagination-total-count'),
                    ),
                    currentPage: Number(
                        meta.response.headers.get('x-pagination-current-page'),
                    ),
                    pageCount: Number(
                        meta.response.headers.get('x-pagination-page-count'),
                    ),
                    perPage: Number(
                        meta.response.headers.get('x-pagination-per-page'),
                    ),
                };
            },
            providesTags: [{type: 'Quizzes', id: 'LIST'}],
        }),
        getQuiz: builder.query<Quiz, string>({
            query: id => `admin/quizzes/${id}`,
            providesTags: (result, error, id) => [{type: 'Quizzes', id}],
        }),
        addQuiz: builder.mutation<Quiz, Partial<Quiz>>({
            query(body) {
                return {
                    url: `admin/quizzes`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{type: 'Quizzes', id: 'LIST'}],
        }),
        updateQuiz: builder.mutation<Quiz, Partial<Quiz>>({
            query: ({id, ...patch}) => ({
                url: `admin/quizzes/${id}?debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['Quizzes'],
        }),
        deleteSessions: builder.mutation<number, Partial<Quiz>>({
            query: ({id}) => ({
                url: `admin/quiz/delete-sessions-data?id=${id}&debug=1`,
                method: 'GET',
            }),
        }),
        cloneQuiz: builder.mutation<string, Partial<Quiz>>({
            query: ({id}) => ({
                url: `admin/quiz/clone?id=${id}&debug=1`,
                method: 'GET',
            }),
            invalidatesTags: [{type: 'Quizzes', id: 'LIST'}],
        }),

        // АПИ вопросов
        getQuestions: builder.query<Question[], string>({
            query: (quizId: string) =>
                'admin/questions?expand=answerOptions,images&per-page=9999&quiz_id=' +
                quizId +
                '&sort=-is_first',
            providesTags: [{type: 'Questions', id: 'LIST'}],
        }),
        getQuestion: builder.query<Question, string>({
            query: id => `admin/questions/${id}`,
            providesTags: (result, error, id) => [{type: 'Questions', id}],
        }),
        addQuestion: builder.mutation<Question, Partial<Question>>({
            query(body) {
                return {
                    url: `admin/questions`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags(_, __, query) {
                return [
                    {type: 'Questions', id: 'LIST'},
                    {type: 'Quizzes', id: query.quiz_id},
                    'Connections',
                ];
            },
        }),
        updateQuestion: builder.mutation<Question, Partial<Question>>({
            query: ({id, ...patch}) => ({
                url: `admin/questions/${id}?expand=answerOptions&debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['Questions', 'Results', 'Connections'],
        }),

        // АПИ результатов
        getResults: builder.query<Result[], string>({
            query: (quizId: string) =>
                'admin/results?expand=images&per-page=9999&quiz_id=' + quizId,
            providesTags: [{type: 'Results', id: 'LIST'}],
        }),
        getResult: builder.query<Result, string>({
            query: id => `admin/results/${id}`,
            providesTags: (result, error, id) => [{type: 'Results', id}],
        }),
        addResult: builder.mutation<Result, Partial<Result>>({
            query(body) {
                return {
                    url: `admin/results`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{type: 'Results', id: 'LIST'}, 'Connections'],
        }),
        updateResult: builder.mutation<Result, Partial<Result>>({
            query: ({id, ...patch}) => ({
                url: `admin/results/${id}?debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['Results'],
        }),
        deleteResult: builder.mutation<Result, string>({
            query: id => ({
                url: `admin/results/${id}?debug=1`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Results'],
        }),

        // АПИ форм
        getForms: builder.query<Form[], string>({
            query: (quizId: string) =>
                'admin/forms?expand=images&per-page=9999&quiz_id=' + quizId,
            providesTags: [{type: 'Forms', id: 'LIST'}],
        }),
        getForm: builder.query<Form, string>({
            query: id => `admin/forms/${id}`,
            providesTags: (result, error, id) => [{type: 'Forms', id}],
        }),
        addForm: builder.mutation<Form, Partial<Form>>({
            query(body) {
                return {
                    url: `admin/forms?debug=1`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags(_, __, query) {
                return [
                    {type: 'Forms', id: 'LIST'},
                    {type: 'Quizzes', id: query.quiz_id},
                    'Connections',
                ];
            },
        }),
        updateForm: builder.mutation<Form, Partial<Form>>({
            query: ({id, ...patch}) => ({
                url: `admin/forms/${id}?debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['Forms'],
        }),
        deleteForm: builder.mutation<Form, string>({
            query: id => ({
                url: `admin/forms/${id}?debug=1`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Forms'],
        }),

        // АПИ страниц
        getPages: builder.query<Page[], string>({
            query: (quizId: string) =>
                'admin/pages?expand=images&per-page=9999&quiz_id=' + quizId,
            providesTags: [{type: 'Pages', id: 'LIST'}],
        }),
        getPage: builder.query<Page, string>({
            query: id => `admin/pages/${id}`,
            providesTags: (result, error, id) => [{type: 'Pages', id}],
        }),
        addPage: builder.mutation<Page, Partial<Page>>({
            query(body) {
                return {
                    url: `admin/pages?debug=1`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags(_, __, query) {
                return [
                    {type: 'Pages', id: 'LIST'},
                    {type: 'Quizzes', id: query.quiz_id},
                    'Connections',
                ];
            },
        }),
        updatePage: builder.mutation<Page, Partial<Page>>({
            query: ({id, ...patch}) => ({
                url: `admin/pages/${id}?debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['Pages'],
        }),
        deletePage: builder.mutation<Page, string>({
            query: id => ({
                url: `admin/pages/${id}?debug=1`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Pages'],
        }),

        // АПИ вариантов ответов
        getAnswerOptions: builder.query<AnswerOption[], string>({
            query: (questionId: string) =>
                'admin/answer_options?question_id=' +
                questionId +
                '&per-page=9999&sort=order',
            providesTags: [{type: 'AnswerOptions', id: 'LIST'}],
        }),
        getAnswerOption: builder.query<AnswerOption, string>({
            query: id => `admin/answer_options/${id}`,
            providesTags: (result, error, id) => [{type: 'AnswerOptions', id}],
        }),
        addAnswerOption: builder.mutation<AnswerOption, Partial<AnswerOption>>({
            query(body) {
                return {
                    url: `admin/answer_options`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{type: 'AnswerOptions', id: 'LIST'}],
        }),
        updateAnswerOption: builder.mutation<
            AnswerOption,
            Partial<AnswerOption>
        >({
            query: ({id, ...patch}) => ({
                url: `admin/answer_options/${id}?debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['AnswerOptions'],
        }),

        // АПИ отношений
        getConnections: builder.query<Connection[], string>({
            query: (quizId: string) =>
                'admin/connections?expand=connectionConditions&per-page=9999&quiz_id=' +
                quizId,
            providesTags: [{type: 'Connections', id: 'LIST'}],
        }),
        getConnection: builder.query<Connection, string>({
            query: id => `admin/connections/${id}`,
            providesTags: (result, error, id) => [{type: 'Connections', id}],
        }),
        addConnection: builder.mutation<Connection, Partial<Connection>>({
            query(body) {
                return {
                    url: `admin/connections`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Connections'],
        }),
        updateConnection: builder.mutation<Connection, Partial<Connection>>({
            query: ({id, ...patch}) => ({
                url: `admin/connections/${id}?expand=connectionConditions&debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['Connections'],
        }),
        deleteConnection: builder.mutation<Connection, string>({
            query: id => ({
                url: `admin/connections/${id}?debug=1`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Connections'],
        }),
    }),
});

export const {
    useGetQuizzesQuery,
    useAddQuizMutation,
    useUpdateQuizMutation,
    useGetQuizQuery,
    useCloneQuizMutation,

    useGetAnswerOptionsQuery,
    useAddAnswerOptionMutation,
    useUpdateAnswerOptionMutation,
    useGetAnswerOptionQuery,

    useGetQuestionsQuery,
    useAddQuestionMutation,
    useUpdateQuestionMutation,
    useGetQuestionQuery,

    useDeleteConnectionMutation,
    useGetConnectionsQuery,
    useAddConnectionMutation,
    useUpdateConnectionMutation,
    useGetConnectionQuery,

    useGetResultsQuery,
    useAddResultMutation,
    useUpdateResultMutation,
    useGetResultQuery,
    useDeleteResultMutation,

    useGetFormsQuery,
    useAddFormMutation,
    useUpdateFormMutation,
    useGetFormQuery,
    useDeleteFormMutation,

    useDeleteSessionsMutation,

    useGetPagesQuery,
    useAddPageMutation,
    useUpdatePageMutation,
    useGetPageQuery,
    useDeletePageMutation,
} = quizApi;
