import React from 'react';
import {OpenInNewOutlined, QuizOutlined} from '@mui/icons-material';
import {Quiz} from '../../../redux/types';
import {useAuth} from '../../../redux/hooks/useAuth';
type Props = {
    quiz: Quiz;
};
const GoToQuizLink: React.FC<Props> = ({quiz}) => {
    const {user} = useAuth();

    const quizLink = () => {
        let link = null;
        const urlParams =
            '?utm_source=panel&utm_content=user:' + user?.username;
        if (quiz.domain) {
            link = 'https://' + quiz.domain + urlParams;
        } else if (quiz.url_id) {
            link =
                process.env.REACT_APP_MAIN_QUIZ_URL +
                '/' +
                quiz.url_id +
                urlParams;
        }

        return link ? (
            <a href={link} target="_blank" title="Открыть квиз">
                <OpenInNewOutlined className="hover:text-gray-600" />
            </a>
        ) : null;
    };

    return quizLink();
};

export default GoToQuizLink;
