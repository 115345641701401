import React, {useState} from 'react';
import {Quiz} from '../../../redux/types';
import GraphBlock from './GraphBlock';
import NewQuestionModal from './NewQuestionModal';
import {Add} from '@mui/icons-material';
import {useGetQuestionsQuery} from '../../../redux/services/quizApi';

type Props = {
    quiz: Quiz;
};

const QuestionsForm: React.FC<Props> = ({quiz}) => {
    const {
        data: questions,
        isFetching,
        isLoading,
    } = useGetQuestionsQuery(quiz.id);
    const [newQuestionModalOpen, setNewQuestionModalOpen] = useState(false);

    if (!questions) return null;

    return (
        <div className="">
            <GraphBlock
                quiz={quiz}
                questions={questions}
                newQuestionModalOpen={newQuestionModalOpen}
                setNewQuestionModalOpen={setNewQuestionModalOpen}
            />
        </div>
    );
};
export default QuestionsForm;
