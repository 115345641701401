import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import {Mutex} from 'async-mutex';
import {logout, setCredentials} from '../slices/authSlice';
import {RootState} from '../store';
import {BaseQueryResult} from '@reduxjs/toolkit/dist/query/baseQueryTypes';

// create a new mutex
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL_PREFIX + '/v1/',
    credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await baseQuery(
                    {
                        url: '/auth/refresh-token?debug=1',
                        method: 'POST',
                        credentials: 'include',
                    },
                    api,
                    extraOptions,
                );

                if (
                    refreshResult.data &&
                    (refreshResult as BaseQueryResult<any>)?.data?.status ===
                        'ok'
                ) {
                    api.dispatch(
                        setCredentials(refreshResult.data as {token: string}),
                    );
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(logout());
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};
