import React from 'react';
import {Block, Error, WarningAmberOutlined} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';

type Props = {
    message?: string;
    title?: string;
    icon?: any;
};
const ErrorPage: React.FC<Props> = ({title, icon, message}) => {
    const navigate = useNavigate();

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="max-w-2xl m-auto mt-16">
                <div className="text-center px-4">
                    <div className="inline-flex mb-8 text-[10em]">
                        <WarningAmberOutlined
                            fontSize={'inherit'}
                            className="text-yellow-500"
                        />
                    </div>
                    {title && <div className="font-bold">{title}</div>}
                    <div className="mb-6">{message ?? 'Произошла ошибка.'}</div>
                    <button
                        onClick={() => navigate(-1)}
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                        Вернуться назад
                    </button>
                </div>
            </div>
        </div>
    );
};
ErrorPage.whyDidYouRender = true;
export default ErrorPage;
