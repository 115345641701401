import React, {ReactNode, useState} from 'react';

type Props = {
    warning: string;
};

const Warning: React.FC<Props> = React.forwardRef((props, ref) => {
    return <div className="">{props.warning}</div>;
});

export default Warning;
