import React, {useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
} from '@mui/material';
import {
    apiValidationErrorResponse,
    Question,
    Result,
} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import MUITheme from '../../Theme/MUITheme';
import ImagesForm from '../../Elements/ImagesForm';
import AnswerOptionForm from './QuestionBlock/AnswerOptionForm';
import {
    useUpdateQuestionMutation,
    useUpdateResultMutation,
} from '../../../redux/services/quizApi';
import {RESULT_TYPES} from '../../../constants';

type Props = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    result: Result;
};
const EditResultModal: React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    result,
}) => {
    const [updateResult] = useUpdateResultMutation();
    const defaultValues = {
        title: result.title ?? '',
        subtitle: result.subtitle ?? '',
        description: result.description ?? '',
        redirect_url: result.redirect_url ?? '',
        images: result.images ?? [],
        type: result.type ?? 'redirect',
        status: result.status ?? '',
    };
    const form = useForm<Result>({
        defaultValues: defaultValues,
    });
    const watchType = form.watch('type');

    useEffect(() => {
        form.reset(defaultValues);
    }, [result]);

    const {control} = form;

    const onSubmit = async (data: Result) => {
        await updateResult({...data, id: result.id})
            .unwrap()
            .then(() => {
                toast.success('Результат успешно обновлен.');
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<Result>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    return (
        <ModalBasic
            id="basic-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title="Редактирование результата">
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
                <Controller
                    control={control}
                    name="title"
                    // defaultValue={question?.title}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="Название"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />

                <Controller
                    control={control}
                    name="type"
                    render={({field, fieldState}) => (
                        <div className="mt-2">
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <InputLabel>Тип результата</InputLabel>
                                    <Select {...field} size="small">
                                        {RESULT_TYPES.map(type => (
                                            <MenuItem
                                                key={type.id}
                                                value={type.id}>
                                                {type.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="images"
                    // defaultValue={question?.images}
                    render={({field, fieldState}) => (
                        <div
                            className="mt-5"
                            hidden={watchType !== 'image_description'}>
                            <ImagesForm
                                ownerId={result.id}
                                {...field}
                                {...fieldState}
                            />
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="subtitle"
                    // defaultValue={question?.subtitle ?? ''}
                    render={({field, fieldState}) => (
                        <div
                            className="mt-2"
                            hidden={watchType !== 'image_description'}>
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Подзаголовок"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="description"
                    // defaultValue={question?.description ?? ''}
                    render={({field, fieldState}) => (
                        <div
                            className="mt-2"
                            hidden={watchType !== 'image_description'}>
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        multiline
                                        rows="4"
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Описание"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="redirect_url"
                    // defaultValue={question?.video_url ?? ''}
                    render={({field, fieldState}) => (
                        <div className="mt-2" hidden={watchType !== 'redirect'}>
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Ссылка для переадресации"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={
                                            fieldState.error?.message ??
                                            'Для проброса utm меток используйте макросы, например {utm_source}'
                                        }
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />
            </div>

            {/* Modal footer */}
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={form.handleSubmit(onSubmit)}>
                        Сохранить
                    </button>
                </div>
            </div>
        </ModalBasic>
    );
};
export default EditResultModal;
