import {isRejectedWithValue} from '@reduxjs/toolkit';
import type {MiddlewareAPI, Middleware} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {errorMessageActionEnum} from '../types';
import {logout} from '../slices/authSlice';

export const errorLogger: Middleware =
    (api: MiddlewareAPI) => next => action => {
        if (isRejectedWithValue(action)) {
            const singleMessage = action.payload?.data?.message;
            if (singleMessage) {
                const messageArr = singleMessage.split('||');
                if (messageArr[0]) toast.warn(messageArr[0]);
                if (messageArr[1]) doAction(messageArr[1], api);
            }
        }

        return next(action);
    };

const doAction = (
    actionMessage: errorMessageActionEnum,
    api: MiddlewareAPI,
) => {
    switch (actionMessage) {
        case errorMessageActionEnum.NEED_ACTION_LOGOUT:
            api.dispatch(logout());
    }
};
