import React from 'react';
import {Control, Controller} from 'react-hook-form';
import {Delete, Settings} from '@mui/icons-material';
import {AnswerOption, Question} from '../../../../redux/types';
import MUITheme from '../../../Theme/MUITheme';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Switch,
    TextField,
    ThemeProvider,
} from '@mui/material';

type Props = {
    remove: (index: number) => void;
    index: number;
    field: AnswerOption;
    control: Control<Question>;
    question: Question;
};

const AnswerOptionForm: React.FC<Props> = ({
    remove,
    index,
    field,
    control,
    question,
}) => {
    const deleteAO = () => {
        if (control._formValues.answerOptions[index].title === undefined) {
            remove(index);
        } else {
            window.confirm(
                'Уверены, что хотите удалить этот вариант ответа?',
            ) && remove(index);
        }
    };

    const toggleSettingsVisibility = (index: number) => {
        const settings = document.getElementsByClassName('optionsSettings');
        Array.from(settings).map(ele => ele.classList.add('hidden'));
        const show = document.getElementById(`option-${index}-settings`);
        show?.classList.remove('hidden');
    };

    return (
        <div className="flex flex-row" key={field.id}>
            <div className="basis-10/12 mb-4 pr-1">
                <Controller
                    control={control}
                    name={`answerOptions.${index}.title`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="Текст ответа"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />

                <div
                    className="my-1 hidden optionsSettings"
                    id={`option-${index}-settings`}>
                    <Controller
                        control={control}
                        name={`answerOptions.${index}.onclick_action`}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="JS событие при клике на вариант ответа"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>
            </div>

            {question.type === 'checkbox' && (
                <div className="basis-1/12">
                    <Controller
                        control={control}
                        name={`answerOptions.${index}.is_right`}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControlLabel
                                    label={
                                        <span style={{fontSize: '0.65rem'}}>
                                            Правильный
                                        </span>
                                    }
                                    className="text-sm text-gray-400"
                                    checked={Boolean(field.value)}
                                    control={
                                        <Switch
                                            {...field}
                                            color="primary"
                                            size="small"
                                            inputRef={field.ref}
                                        />
                                    }
                                    labelPlacement="top"
                                />
                            </ThemeProvider>
                        )}
                    />
                </div>
            )}

            {question.type === 'radiobutton' && (
                <div className="basis-1/12">
                    <Controller
                        control={control}
                        name={`answerOptions.${index}.points`}
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Баллы"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>
            )}

            <div className="basis-1/12 pl-1 pt-4">
                <button className="align-middle" onClick={deleteAO}>
                    <Delete />
                </button>
                <button
                    className="align-middle"
                    onClick={() => toggleSettingsVisibility(index)}>
                    <Settings />
                </button>
            </div>
        </div>
    );
};

export default AnswerOptionForm;
