import React from 'react';
import {Quiz} from '../../redux/types';
import {Link} from 'react-router-dom';
import {Circle} from '@mui/icons-material';
import GoToQuizLink from './actionLinks/GoToQuizLink';
type Props = {
    quiz: Quiz;
};
const QuizzesListItem: React.FC<Props> = ({quiz}) => {
    return (
        <tr>
            <td className="px-2 first:pl-5 last:pr-5 py-3 text-[1.1em] whitespace-nowrap">
                <span
                    className={
                        'text-' +
                        (quiz.status === 'on' ? 'green-500' : 'gray-200')
                    }
                    title={quiz.status === 'on' ? 'Включен' : 'Выключен'}>
                    <Circle fontSize="inherit" />
                </span>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-full">
                <div className="font-medium text-gray-800 hover:text-indigo-600">
                    <Link to={'/quiz/' + quiz.id}>{quiz.title}</Link>
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-gray-400">
                <GoToQuizLink quiz={quiz} />
            </td>
        </tr>
    );
};

export default QuizzesListItem;
