import React, {useEffect, useState} from 'react';
import {
    apiValidationErrorResponse,
    Question,
    Quiz,
    Connection,
} from '../../../../redux/types';
import {Add, Edit, Link, Remove} from '@mui/icons-material';
import {useDeleteConnectionMutation} from '../../../../redux/services/quizApi';

import {toast} from 'react-toastify';
import EditResultModal from '../EditResultModal';
import EditConditionsModal from '../EditConditionsModal';

type Props = {
    connection: Connection;
    questions: Question[];
    onElementUnselect: () => void;
    setNeedShowCondModal: (need: boolean) => void;
    needShowCondModal: boolean;
};

const ConnectionMenu: React.FC<Props> = React.forwardRef((props, ref) => {
    const [editConditionsModalOpen, setEditConditionsModalOpen] =
        useState(false);
    const [deleteConnection] = useDeleteConnectionMutation();

    const hardDelete = async () => {
        await deleteConnection(props.connection.id)
            .unwrap()
            .then(() => {
                props.onElementUnselect();
                toast.success('Связь удалена');
            })
            .catch((response: apiValidationErrorResponse<Question>) => {
                console.error(response);
            });
    };
    useEffect(() => {
        if (props.needShowCondModal) {
            setEditConditionsModalOpen(true);
            props.setNeedShowCondModal(false);
        }
    }, [props.needShowCondModal]);

    return (
        <div>
            <EditConditionsModal
                connection={props.connection}
                modalOpen={editConditionsModalOpen}
                setModalOpen={setEditConditionsModalOpen}
                questions={props.questions}
                onElementUnselect={props.onElementUnselect}
            />

            <button
                className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                aria-controls="basic-modal"
                onClick={e => {
                    e.stopPropagation();
                    setEditConditionsModalOpen(true);
                }}>
                <span className="hidden xs:block">Управление условиями</span>
            </button>

            <button
                className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                aria-controls="basic-modal"
                onClick={e => {
                    e.stopPropagation();
                    if (window.confirm('Удалить связь?')) {
                        hardDelete();
                    }
                }}>
                <Remove className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="hidden xs:block ml-2">Удалить связь</span>
            </button>
        </div>
    );
});

export default ConnectionMenu;
