import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import {FileDownloadOutlined} from '@mui/icons-material';
import DropdownButtonMenu from '../../../components/Elements/DropdownButtonMenu';
import {LeadReportData, LeadReportFilters} from '../../../redux/types';
import moment from 'moment';

type Props = {
    data: LeadReportData;
    filters: LeadReportFilters;
};

const ExportButton: React.FC<Props> = ({data, filters}) => {
    const CSVColumns = [
        {
            id: '1',
            displayName: 'Квиз',
        },
        {
            id: '2',
            displayName: 'Дата',
        },
        {
            id: '3',
            displayName: 'Лиды',
        },
    ];

    const getCSVData = () => {
        const out: {1: string; 2: string; 3: string}[] = [];

        Object.keys(data.leads).map(quizName => {
            out.push({
                '1': quizName,
                '2': '',
                '3': '',
            });

            data.leads[quizName].map(row => {
                out.push({
                    '1': '',
                    '2': moment(row.date).format('DD.MM.YYYY'),
                    '3': String(row.count),
                });
            });
        });

        return out;
    };

    const getFileName = () => {
        let name = 'leads_report';
        if (filters.dateRange.from && filters.dateRange.to) {
            name +=
                '_' +
                (filters.dateRange.from === filters.dateRange.to
                    ? filters.dateRange.from
                    : filters.dateRange.from + '-' + filters.dateRange.to);
        }
        return name;
    };

    return (
        <div className="mb-1 text-right">
            <DropdownButtonMenu
                icon={<FileDownloadOutlined />}
                className="relative inline-flex"
                align="right"
                title="Загрузить таблицу">
                <li>
                    <CsvDownloader
                        filename={getFileName()}
                        extension=".csv"
                        separator=";"
                        // wrapColumnChar=""
                        columns={CSVColumns}
                        datas={getCSVData()}>
                        <button className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                            CSV
                        </button>
                    </CsvDownloader>
                </li>
            </DropdownButtonMenu>
        </div>
    );
};
ExportButton.whyDidYouRender = true;
export default React.memo(ExportButton);
