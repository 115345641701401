import React, {useRef} from 'react';
import {LeadReportFilters} from '../../../redux/types';
import {useGetLeadReportDataQuery} from '../../../redux/services/statApi';
import ActivityIndicator from '../../../components/ActivityIndicator';
import moment from 'moment';
import ExportButton from './ExportButton';

type Props = {
    filters: LeadReportFilters;
};

const Table: React.FC<Props> = ({filters}) => {
    const tableRef = useRef(null);

    const {data, isFetching, isLoading} = useGetLeadReportDataQuery({
        filters: filters,
    });

    if (isFetching || isLoading) return <ActivityIndicator />;

    if (!data?.leads || !Object.keys(data.leads).length)
        return <div className="mt-3">Отсутствуют данные для отображения</div>;

    return (
        <div className="mt-5">
            <ExportButton data={data} filters={filters} />
            <table
                className="text-xs table-auto w-full border-t-gray-400"
                ref={tableRef}>
                <tbody className="text-sm divide-y divide-gray-100">
                    <tr>
                        <td className="text-gray-400 bg-gray-50 rounded-sm uppercase text-left w-full pl-3">
                            Дата
                        </td>
                        <td className="text-gray-400 bg-gray-50 rounded-sm uppercase pr-3">
                            Лиды
                        </td>
                    </tr>
                    {Object.keys(data.leads).map((quizName, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td
                                        colSpan={2}
                                        className="text-center bg-gray-50">
                                        {quizName}
                                    </td>
                                </tr>
                                {data.leads[quizName].map((row, i) => (
                                    <tr key={i}>
                                        <td className="pl-3 text-xs">
                                            {moment(row.date).format(
                                                'DD.MM.YYYY',
                                            )}
                                        </td>
                                        <td className="pr-3 text-xs">
                                            {row.count}
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
Table.whyDidYouRender = true;
export default React.memo(Table);
