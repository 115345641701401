import React, {useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {Controller, useForm} from 'react-hook-form';
import {FormControl, TextField, ThemeProvider} from '@mui/material';
import {
    apiValidationErrorResponse,
    Connection,
    Quiz,
    Form,
    Page,
} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import MUITheme from '../../Theme/MUITheme';
import {useAddPageMutation} from '../../../redux/services/quizApi';

type Props = {
    quiz: Quiz;
    modalOpen: boolean | 'page' | 'modal_window';
    setModalOpen: (open: boolean) => void;
    createConnections: (id: string) => Connection[];
};
const NewPageModal: React.FC<Props> = ({
    quiz,
    modalOpen,
    setModalOpen,
    createConnections,
}) => {
    const [addPage, result] = useAddPageMutation();
    const form = useForm<Page>({
        defaultValues: {title: ''},
    });
    const {control} = form;
    const newPageId = nanoid(10);

    const onSubmit = async (data: Page) => {
        const newPageData = {
            ...data,
            id: newPageId,
            is_modal: modalOpen === 'modal_window',
            quiz_id: quiz.id,
            connections: createConnections(newPageId),
        };

        await addPage(newPageData)
            .unwrap()
            .then(() => {
                toast.success(
                    modalOpen === 'modal_window'
                        ? 'Модальное окно добавлено'
                        : 'Страница добавлена',
                );
                form.reset();
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<Page>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
            });
    };

    return (
        <ModalBasic
            id="basic-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title={
                modalOpen === 'modal_window'
                    ? 'Новое модальное окно'
                    : 'Новая страница'
            }>
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
                <div className="text-sm">
                    <div className="py-2">
                        <Controller
                            control={control}
                            name="title"
                            defaultValue={''}
                            render={({field, fieldState}) => (
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth={true}>
                                        <TextField
                                            {...field}
                                            error={Boolean(fieldState.error)}
                                            label="Заголовок"
                                            variant="standard"
                                            size="small"
                                            inputRef={field.ref}
                                            helperText={
                                                fieldState.error?.message
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            )}
                        />
                    </div>
                </div>
            </div>

            {/* Modal footer */}
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={form.handleSubmit(onSubmit)}>
                        Сохранить
                    </button>
                </div>
            </div>
        </ModalBasic>
    );
};
export default NewPageModal;
