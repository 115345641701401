import React, {useState} from 'react';

import {apiValidationErrorResponse, Form, Quiz} from '../../../../redux/types';
import {Add, Edit, FirstPage, Link, Remove} from '@mui/icons-material';
import {toast} from 'react-toastify';
import {useUpdateFormMutation} from '../../../../redux/services/quizApi';
import EditFormModal from '../EditFormModal';

type Props = {
    quiz: Quiz;
    form: Form;
    bindingMode: boolean;
    setBindingMode: (mode: boolean) => void;
    setElementFirst: (id: string) => void;
};

const FormMenu: React.FC<Props> = React.forwardRef((props, ref) => {
    const [editFormModalOpen, setEditFormModalOpen] = useState(false);
    const [updateForm] = useUpdateFormMutation();

    const softDelete = async () => {
        await updateForm({...props.form, status: 'deleted'})
            .unwrap()
            .then(() => {
                toast.success('Форма удалена');
            })
            .catch((response: apiValidationErrorResponse<Form>) => {
                console.error(response);
            });
    };

    return (
        <div>
            <EditFormModal
                formObj={props.form}
                modalOpen={editFormModalOpen}
                setModalOpen={setEditFormModalOpen}
            />

            <button
                className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                aria-controls="basic-modal"
                onClick={e => {
                    e.stopPropagation();
                    setEditFormModalOpen(true);
                }}>
                <Edit className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="hidden xs:block ml-2">Редактировать</span>
            </button>

            {props.quiz.first_element_id !== props.form.id && (
                <button
                    className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        props.setElementFirst(props.form.id);
                    }}>
                    <FirstPage className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Сделать первой</span>
                </button>
            )}

            {props.bindingMode ? (
                <button
                    className="btn-xs  bg-red-500 hover:bg-red-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        props.setBindingMode(false);
                    }}>
                    <Link className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Отменить связь</span>
                </button>
            ) : (
                <button
                    className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        props.setBindingMode(true);
                    }}>
                    <Link className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Связать с ...</span>
                </button>
            )}

            {props.quiz.first_element_id !== props.form.id && (
                <button
                    className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        if (window.confirm('Удалить форму?')) {
                            softDelete();
                        }
                    }}>
                    <Remove className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Удалить</span>
                </button>
            )}
        </div>
    );
});

export default FormMenu;
