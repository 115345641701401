import {ANSWER_TYPES} from '../constants';

export const titleByType = (type: string) => {
    return ANSWER_TYPES.find(opt => opt.id === type)?.title;
};

export const allScopesGranted = (
    grantedScopes: string,
    needScopes: string[],
): boolean => {
    let isOk = true;
    needScopes.map(search => {
        if (!grantedScopes.includes(search)) isOk = false;
    });
    return isOk;
};
