import React, {useState} from 'react';
import {FieldError} from 'react-hook-form';
import {TextField} from '@mui/material';
import {Link} from 'react-router-dom';
import {ArrowForward, ArrowForwardIos} from '@mui/icons-material';

type Props = {
    breadcrumbs: Breadcrumb[];
};

export type Breadcrumb = {
    title: string;
    url?: string;
};

const Breadcrumbs: React.FC<Props> = ({breadcrumbs}) => {
    return (
        <div className="pr-2">
            <div className="text-center">
                <ul className="inline-flex flex-wrap text-sm font-medium">
                    {breadcrumbs.map((b, index) => (
                        <li
                            className="flex items-center align-bottom"
                            key={index}>
                            {b.url ? (
                                <Link
                                    to={b.url}
                                    className="text-gray-500 hover:text-indigo-500">
                                    {b.title}
                                </Link>
                            ) : (
                                <span className="text-2xl">{b.title}</span>
                            )}
                            {breadcrumbs.length !== index + 1 && (
                                <ArrowForwardIos
                                    className="h-4 w-4 fill-current text-gray-400 mx-3"
                                    fontSize={'inherit'}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Breadcrumbs;
