import React, {useEffect, useState} from 'react';
import {
    apiValidationErrorResponse,
    Quiz,
    QuizStyle,
} from '../../../redux/types';
import SettingsForm from '../SettingsForm';
import TabTitle from './TabTitle';
import QuestionsForm from '../QuestionsForm';
import {useLocation, useNavigate} from 'react-router-dom';
import DesignForm from '../DesignForm';
import IntegrationsForm from '../IntegrationsForm';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useUpdateQuizMutation} from '../../../redux/services/quizApi';
import Breadcrumbs from '../../Elements/Breadcrumbs';

type Props = {
    quiz: Quiz;
};

const QuizForm: React.FC<Props> = React.forwardRef(({quiz}, ref) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [updateQuiz, result] = useUpdateQuizMutation();

    const [activeTab, setActiveTab] = useState('settings');
    useEffect(() => {
        if (location.hash) setActiveTab(location.hash.substring(1));
    }, [location.hash]);

    const defaultValues = {
        url_id: quiz?.url_id ?? '',
        title: quiz?.title ?? '',
        domain: quiz?.domain ?? '',
        status: quiz?.status ?? 'on',
        cover_title: quiz?.cover_title ?? '',
        cover_subtitle: quiz?.cover_subtitle ?? '',
        cover_text: quiz?.cover_text ?? '',
        cover_images: quiz?.cover_images ?? [],
        shuffle_questions: quiz?.shuffle_questions ?? false,
        tenacious_sessions: quiz?.tenacious_sessions ?? true,
        prevent_changes: quiz?.prevent_changes ?? false,
        allowed_emails: quiz?.allowed_emails ?? '',
        prohibited_phones: quiz?.prohibited_phones ?? '',
        notice_emails: quiz?.notice_emails ?? '',
        moderators_emails: quiz?.moderators_emails ?? '',
        quiz_time_limit: quiz?.quiz_time_limit ?? '',
        question_time_limit: quiz?.question_time_limit ?? '',
        logotypes: quiz?.logotypes ?? [],
        favicons: quiz?.favicons ?? [],
        styleParams: quiz?.styleParams ?? [],
        extra_styles: quiz?.extra_styles ?? '',
        styles: [] as QuizStyle[],
    };

    quiz?.styleParams?.map((sp, index) => {
        const style = quiz.styles?.find(s => s.prop === sp.title);
        defaultValues.styles.push(
            style ?? {
                id: undefined,
                quiz_id: quiz.id,
                prop: sp.title,
                value: '',
            },
        );
    });

    const form = useForm<Quiz>({
        defaultValues: defaultValues,
    });

    useEffect(() => {
        form.reset(defaultValues);
    }, [quiz]);

    const onSubmit = async (data: Quiz) => {
        // console.log('дата: ', data);
        data.logotypes = data.logotypes?.map(i =>
            Object.assign({}, i, {type: 'logotype'}),
        );
        data.favicons = data.favicons?.map(i =>
            Object.assign({}, i, {type: 'favicon'}),
        );
        data.cover_images = data.cover_images?.map(i =>
            Object.assign({}, i, {type: 'cover'}),
        );

        console.log('датища: ', data);
        await updateQuiz({...data, id: quiz.id})
            .unwrap()
            .then(() => {
                toast.success('Квиз успешно обновлен.');
                // navigate('/');
            })
            .catch((response: apiValidationErrorResponse<Quiz>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    const tabs = [
        {
            id: 'settings',
            title: 'Настройки',
        },
        {
            id: 'graph',
            title: 'Страницы',
        },
        {
            id: 'design',
            title: 'Дизайн',
        },
        {
            id: 'integrations',
            title: 'Интеграции',
        },
    ];

    return (
        <>
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs
                        breadcrumbs={[
                            {title: 'Квизы', url: '/'},
                            {title: quiz.title, url: '/quiz/' + quiz.id},
                            {title: 'Настройка'},
                        ]}
                    />
                </div>
                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <button
                        onClick={form.handleSubmit(onSubmit)}
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                        Сохранить
                    </button>
                    <button
                        onClick={() => navigate('/quiz/' + quiz.id)}
                        className="btn border-gray-200 hover:border-gray-300 text-gray-600 ml-2">
                        Отмена
                    </button>
                </div>
            </div>
            <div className="space-y-4">
                <div>
                    <div className="relative">
                        <div
                            className="absolute bottom-0 w-full h-px bg-gray-200"
                            aria-hidden="true"></div>
                        <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
                            {tabs.map(tab => (
                                <TabTitle
                                    key={tab.id}
                                    isActive={tab.id === activeTab}
                                    title={tab.title}
                                    id={tab.id}
                                />
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <div
                        className={
                            activeTab !== 'settings' ? 'hidden' : 'visible'
                        }>
                        <SettingsForm quiz={quiz} form={form} />
                    </div>
                    <div
                        className={
                            activeTab !== 'graph' ? 'hidden' : 'visible'
                        }>
                        <QuestionsForm quiz={quiz} />
                    </div>
                    <div
                        className={
                            activeTab !== 'design' ? 'hidden' : 'visible'
                        }>
                        <DesignForm quiz={quiz} form={form} />
                    </div>
                    <div
                        className={
                            activeTab !== 'integrations' ? 'hidden' : 'visible'
                        }>
                        <IntegrationsForm quiz={quiz} />
                    </div>
                </div>
            </div>
        </>
    );
});
export default QuizForm;
