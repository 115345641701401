import React from 'react';
import {StatusEnum, User} from '../../../redux/types';
import {GridViewOutlined} from '@mui/icons-material';
import {Link} from 'react-router-dom';
type Props = {
    user: User;
    onClickEditUser: (user: User) => void;
};
const UsersListItem: React.FC<Props> = ({user, onClickEditUser}) => {
    return (
        <tr>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-full">
                <div className="font-medium text-gray-800">
                    <button onClick={() => onClickEditUser(user)}>
                        {user.email}
                    </button>
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-gray-400">
                {statusIcon(user.status)}
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-gray-400">
                <Link to={'/search/' + user.email} title="Квизы пользователя">
                    <GridViewOutlined fontSize={'small'} />
                </Link>
            </td>
        </tr>
    );
};

const statusIcon = (status: StatusEnum) => {
    switch (status) {
        case 'on':
            return <span className="text-green-700">Активен</span>;
        case 'off':
            return <span className="text-red-700">Заблокирован</span>;
        default:
            return <span className="text-grey-700">Удален</span>;
    }
};

export default UsersListItem;
