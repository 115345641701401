import React from 'react';
import {Quiz} from '../../../../redux/types';
import {
    useCloneQuizMutation,
    useDeleteSessionsMutation,
    useUpdateQuizMutation,
} from '../../../../redux/services/quizApi';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

type Props = {
    quiz: Quiz;
};

const OperationsButtons: React.FC<Props> = ({quiz}) => {
    const [deleteSessions] = useDeleteSessionsMutation();
    const [updateQuiz] = useUpdateQuizMutation();
    const [cloneQuiz] = useCloneQuizMutation();
    const navigate = useNavigate();

    const onPressDeleteSessions = () => {
        // e.stopPropagation();
        if (!window.confirm('Удалить безвозвратно все прохождения квиза?'))
            return;
        deleteSessions(quiz)
            .unwrap()
            .then(result => {
                toast.success(`Удалено ${result} сессий`);
            });
    };

    const onPressDeleteQuiz = async () => {
        if (!window.confirm('Удалить квиз?')) return;

        await updateQuiz({id: quiz.id, status: 'deleted'})
            .unwrap()
            .then(() => {
                toast.success('Квиз удален.');
                navigate('/');
            })
            .catch(() => {
                toast.error('Извините, не получилось :(');
            });
    };

    const onPressClone = async () => {
        await cloneQuiz({id: quiz.id, status: 'deleted'})
            .unwrap()
            .then(result => {
                toast.success(`Создан клон квиза`);
                navigate('/editQuiz/' + result + '#settings');
            })
            .catch(() => {
                toast.error('Извините, не получилось :(');
            });
    };

    return (
        <div className="mt-2">
            <button
                className="btn mb-2 bg-red-500 hover:bg-red-600 text-white"
                onClick={onPressDeleteSessions}>
                Удалить данные о прохождениях
            </button>

            <button
                className="ml-2 mb-2 btn bg-red-500 hover:bg-red-600 text-white"
                onClick={onPressDeleteQuiz}>
                Удалить квиз
            </button>

            <button
                className="ml-2 mb-2 btn bg-blue-500 hover:bg-blue-600 text-white"
                onClick={onPressClone}>
                Клонировать
            </button>
        </div>
    );
};
export default OperationsButtons;
