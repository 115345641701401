import {createApi} from '@reduxjs/toolkit/query/react';
import {User} from '../types';
import {baseQueryWithReauth} from './baseQueryWithReauth';
import {BaseQueryMeta} from '@reduxjs/toolkit/dist/query/baseQueryTypes';

type UserListArgs = {
    page: number;
    searchTerm: string;
};
export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['Users'],
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getUsers: builder.query<
            {
                items: User[];
                totalCount: number;
                currentPage: number;
                pageCount: number;
                perPage: number;
            },
            UserListArgs
        >({
            query: ({page = 1, searchTerm = ''}) =>
                `admin/users?sort=title&page=${page}&email=${searchTerm}&debug=1`,
            transformResponse(response: User[], meta: BaseQueryMeta<any>) {
                return {
                    items: response,
                    totalCount: Number(
                        meta.response.headers.get('x-pagination-total-count'),
                    ),
                    currentPage: Number(
                        meta.response.headers.get('x-pagination-current-page'),
                    ),
                    pageCount: Number(
                        meta.response.headers.get('x-pagination-page-count'),
                    ),
                    perPage: Number(
                        meta.response.headers.get('x-pagination-per-page'),
                    ),
                };
            },
            providesTags: [{type: 'Users', id: 'LIST'}],
        }),
        getUser: builder.query<User, string>({
            query: id => `admin/users/${id}`,
            providesTags: (result, error, id) => [{type: 'Users', id}],
        }),
        updateUser: builder.mutation<User, Partial<User>>({
            query: ({id, ...patch}) => ({
                url: `admin/users/${id}?debug=1`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['Users'],
        }),
    }),
});

export const {useGetUsersQuery, useGetUserQuery, useUpdateUserMutation} =
    userApi;
