import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithReauth} from './baseQueryWithReauth';
import {UserResponse} from './authApi';

export type GoogleAuthCode = {
    code: string;
};

export const googleAuthApi = createApi({
    reducerPath: 'googleAuthApi',
    tagTypes: ['SpreadsheetsEmail'],
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        connectSpreadsheetsAccount: builder.mutation<
            GoogleAuthCode,
            Partial<GoogleAuthCode>
        >({
            query(body) {
                return {
                    url: `admin/google_auth/connect-spreadsheets-account`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['SpreadsheetsEmail'],
        }),

        loginByCode: builder.mutation<UserResponse, Partial<GoogleAuthCode>>({
            query(body) {
                return {
                    url: `admin/google_auth/login-by-code`,
                    method: 'POST',
                    body,
                };
            },
        }),

        getSpreadsheetEmail: builder.query<string | boolean, string>({
            query: () => `admin/google_auth/get-connected-spreadsheet-email`,
            providesTags: ['SpreadsheetsEmail'],
        }),
    }),
});

export const {
    useConnectSpreadsheetsAccountMutation,
    useGetSpreadsheetEmailQuery,
    useLoginByCodeMutation,
} = googleAuthApi;
