import React from 'react';
import ErrorPage from '../ErrorPage';
import {useQuiz} from '../../redux/hooks/useQuiz';
import QuizForm from '../../components/QuizForms/QuizForm';

const EditQuizPage = () => {
    const {data: quiz, error} = useQuiz();

    if (error) return <ErrorPage />;
    if (!quiz) return null;

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-white">
            <QuizForm quiz={quiz} />
        </div>
    );
};

export default EditQuizPage;
