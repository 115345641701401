import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import {FileDownloadOutlined} from '@mui/icons-material';
import DropdownButtonMenu from '../../../components/Elements/DropdownButtonMenu';
import {FilledActionsData, FilledResultsData} from './Table';
import {FunnelFilters} from '../../../redux/types';

type Props = {
    totalSessionsCount: number;
    abandonedSessionsCount: number;
    actionsData: FilledActionsData[];
    resultsData: FilledResultsData[];
    filters: FunnelFilters;
};

const ExportButton: React.FC<Props> = ({
    totalSessionsCount,
    abandonedSessionsCount,
    actionsData,
    resultsData,
    filters,
}) => {
    const CSVColumns = [
        {
            id: '1',
            displayName: '',
        },
        {
            id: '2',
            displayName: 'Действий',
        },
        {
            id: '3',
            displayName: 'Действий (%)',
        },
        {
            id: '4',
            displayName: 'Отказов',
        },
        {
            id: '5',
            displayName: 'Отказов (%)',
        },
    ];

    const getCSVData = () => {
        const data = [
            {
                '1': 'Всего сессий',
                '2': String(totalSessionsCount),
                '3': '100%',
                '4': String(abandonedSessionsCount),
                '5':
                    (totalSessionsCount
                        ? Math.round(
                              (abandonedSessionsCount / totalSessionsCount) *
                                  100,
                          )
                        : 0) + '%',
            },
        ];
        data.push({
            '1': 'Этапы',
            '2': '',
            '3': '',
            '4': '',
            '5': '',
        });
        actionsData.map(act =>
            data.push({
                '1':
                    act.title.replace(/[\n\r]+/g, '') +
                    (act.status === 'deleted' ? ' (удалено)' : ''),
                '2': String(act.count),
                '3': String(act.countPercent) + '%',
                '4': String(act.exitsCount),
                '5': String(act.exitsCountPercent) + '%',
            }),
        );

        data.push({
            '1': 'Достижения результатов',
            '2': '',
            '3': '',
            '4': '',
            '5': '',
        });

        resultsData.map(
            res =>
                res.result_title &&
                data.push({
                    '1': res.result_title.replace(/[\n\r]+/g, ''),
                    '2': String(res.count),
                    '3': String(res.countPercent),
                    '4': String(res.exitsCount),
                    '5': String(res.exitsCountPercent),
                }),
        );
        return data;
    };

    const getFileName = () => {
        let name = 'funnel';
        if (filters.dateRange.from && filters.dateRange.to) {
            name +=
                '_' +
                (filters.dateRange.from === filters.dateRange.to
                    ? filters.dateRange.from
                    : filters.dateRange.from + '-' + filters.dateRange.to);
        }
        return name;
    };

    return (
        <div className="mb-1 text-right">
            <DropdownButtonMenu
                icon={<FileDownloadOutlined />}
                className="relative inline-flex"
                align="right"
                title="Загрузить таблицу">
                <li>
                    <CsvDownloader
                        filename={getFileName()}
                        extension=".csv"
                        separator=";"
                        // wrapColumnChar=""
                        columns={CSVColumns}
                        datas={getCSVData()}>
                        <button className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                            CSV
                        </button>
                    </CsvDownloader>
                </li>
            </DropdownButtonMenu>
        </div>
    );
};
ExportButton.whyDidYouRender = true;
export default React.memo(ExportButton);
