import React, {useEffect, useRef, useState} from 'react';
import Transition from '../../utils/Transition';
import {useAuth} from '../../redux/hooks/useAuth';
import {Button} from '@mui/material';
import {logout} from '../../redux/slices/authSlice';
import {useAppDispatch} from '../../redux/hooks';
import {Link} from 'react-router-dom';

const UserMenu: React.FC = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useAppDispatch();

    const trigger = useRef<HTMLButtonElement>(null);
    const dropdown = useRef<HTMLHeadingElement>(null);

    const auth = useAuth();

    // close on click outside
    useEffect(() => {
        const clickHandler = (event: MouseEvent) => {
            if (!dropdown.current) return;
            if (
                !dropdownOpen ||
                dropdown.current.contains(event.target as Element) ||
                trigger.current?.contains(event.target as Element)
            )
                return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({keyCode}: KeyboardEvent) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div className="relative inline-flex">
            <button
                ref={trigger}
                className="inline-flex justify-center items-center group"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}>
                {/*<img className="w-8 h-8 rounded-full" src={UserAvatar} width="32" height="32" alt="User" />*/}
                <div className="flex items-center truncate">
                    <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">
                        {auth.user?.username}
                    </span>
                    <svg
                        className="w-3 h-3 shrink-0 ml-1 fill-current text-gray-400"
                        viewBox="0 0 12 12">
                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                </div>
            </button>

            <Transition
                className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 right-0`}
                show={dropdownOpen}
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                appear={true}>
                <div
                    ref={dropdown}
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}>
                    <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
                        <div className="font-medium text-gray-800">
                            {auth.user?.username}
                        </div>
                        <div className="text-xs text-gray-500 italic whitespace-nowrap">
                            {auth.user?.email}
                        </div>
                    </div>
                    <ul>
                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"*/}
                        {/*        to="/settings"*/}
                        {/*        onClick={() => setDropdownOpen(!dropdownOpen)}>*/}
                        {/*        Settings*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        <li>
                            <Link
                                to={'#'}
                                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                                onClick={() => dispatch(logout())}>
                                Выйти
                            </Link>
                        </li>
                    </ul>
                </div>
            </Transition>
        </div>
    );
};

export default UserMenu;
