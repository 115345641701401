import React, {useEffect, useState} from 'react';
import {Lead2CallLink, Quiz} from '../../../../../redux/types';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {FormControl, TextField, ThemeProvider} from '@mui/material';
import MUITheme from '../../../../Theme/MUITheme';
import {nanoid} from '@reduxjs/toolkit';
import LinkBlock from './LinkBlock';
import {UseFormReturn} from 'react-hook-form/dist/types';

type Props = {
    quiz: Quiz;
    setModalOpen: (open: boolean) => void;
    onSubmit: (
        data: Partial<Quiz>,
        form: UseFormReturn<Quiz, object>,
    ) => Promise<void>;
};

const Lead2Call: React.FC<Props> = ({quiz, setModalOpen, onSubmit}) => {
    const defaultValues = {
        lead2call_link: quiz.lead2call_link ?? '',
        lead2call_links: quiz.lead2call_links ?? [],
    };

    const form = useForm<Quiz>({
        defaultValues: defaultValues,
    });
    const {control, clearErrors} = form;

    useEffect(() => {
        form.reset(defaultValues);
        remove();
        quiz.lead2call_links && append(quiz.lead2call_links);
    }, [quiz]);

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'lead2call_links',
        keyName: 'key',
    });

    const addLinkField = () => {
        const newLink: Lead2CallLink = {
            id: nanoid(10),
            quiz_id: quiz.id,
            utm_source: '',
            link: '',
        };
        append(newLink);
    };

    const linksBlock = () => {
        return (
            <div className="">
                {fields.map((field, index, reset) => (
                    <LinkBlock
                        key={index}
                        remove={remove}
                        index={index}
                        field={field}
                        control={control}
                        quiz={quiz}
                    />
                ))}
                <div className="w-full text-right">
                    <button className="btn-xs" onClick={addLinkField}>
                        + ссылка
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="px-5 pt-4 pb-1">
            <div>
                <Controller
                    control={control}
                    name="lead2call_link"
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="Ссылка"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={
                                        fieldState.error?.message ??
                                        'Если не подойдет ни одна ссылка, настроенная для utm_source, будет использована эта ссылка'
                                    }
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />

                <div className="bg-gray-100 rounded p-2 mt-2">
                    <div className="mb-1 text-sm font-bold">
                        Ссылки для utm_source
                    </div>
                    {linksBlock()}
                </div>
            </div>
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={e => {
                            form.clearErrors();
                            form.handleSubmit(data => onSubmit(data, form))(e);
                        }}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Lead2Call;
