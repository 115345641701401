import React, {useState} from 'react';

import {
    apiValidationErrorResponse,
    Question,
    Result,
} from '../../../../redux/types';
import {Add, Edit, Link, Remove} from '@mui/icons-material';
import {toast} from 'react-toastify';
import {useUpdateResultMutation} from '../../../../redux/services/quizApi';
import EditQuestionModal from '../EditQuestionModal';
import EditResultModal from '../EditResultModal';

type Props = {
    result: Result;
};

const ResultMenu: React.FC<Props> = React.forwardRef((props, ref) => {
    const [editResultModalOpen, setEditResultModalOpen] = useState(false);
    const [updateResult] = useUpdateResultMutation();

    const softDelete = async () => {
        await updateResult({...props.result, status: 'deleted'})
            .unwrap()
            .then(() => {
                toast.success('Результат удален');
            })
            .catch((response: apiValidationErrorResponse<Result>) => {
                console.error(response);
            });
    };

    return (
        <div>
            <EditResultModal
                result={props.result}
                modalOpen={editResultModalOpen}
                setModalOpen={setEditResultModalOpen}
            />

            <button
                className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                aria-controls="basic-modal"
                onClick={e => {
                    e.stopPropagation();
                    setEditResultModalOpen(true);
                }}>
                <Edit className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="hidden xs:block ml-2">Редактировать</span>
            </button>

            <button
                className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                aria-controls="basic-modal"
                onClick={e => {
                    e.stopPropagation();
                    if (window.confirm('Удалить результат?')) {
                        softDelete();
                    }
                }}>
                <Remove className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="hidden xs:block ml-2">Удалить</span>
            </button>
        </div>
    );
});

export default ResultMenu;
