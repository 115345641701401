import React from 'react';
import {Quiz} from '../../../redux/types';
import {FormControl, TextField, ThemeProvider} from '@mui/material';
import MUITheme from '../../Theme/MUITheme';
import {Controller, useForm} from 'react-hook-form';
import {UseFormReturn} from 'react-hook-form/dist/types';
import ImageForm from '../../Elements/ImageForm';

type Props = {
    quiz: Quiz;
    form: UseFormReturn<Quiz, object>;
};

const DesignForm: React.FC<Props> = ({quiz, form}) => {
    const {
        formState: {errors},
        control,
        register,
        watch,
    } = form;

    return (
        <div className="flex flex-row">
            <div className="basis-1/4">
                <div className="flex flex-row">
                    <div className="basis-1/2">
                        <Controller
                            control={control}
                            name="logotypes"
                            // defaultValue={quiz?.logotypes}
                            render={({field, fieldState}) => (
                                <div className="pr-3 ">
                                    <div className="text-xs">Логотип</div>
                                    <ImageForm
                                        ownerId={quiz.id}
                                        {...field}
                                        {...fieldState}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className="basis-1/2">
                        <Controller
                            control={control}
                            name="favicons"
                            // defaultValue={quiz?.favicons}
                            render={({field, fieldState}) => (
                                <div className="pr-3 ">
                                    <div className="text-xs">Favicon</div>
                                    <ImageForm
                                        ownerId={quiz.id}
                                        {...field}
                                        {...fieldState}
                                    />
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="basis-1/4 p-3">
                {quiz.styleParams &&
                    quiz.styleParams.map((sp, index) => (
                        <div className="pr-3" key={sp.title}>
                            <input
                                {...register(`styles.${index}.prop`)}
                                value={sp.title}
                                type="hidden"
                            />
                            <input
                                {...register(`styles.${index}.quiz_id`)}
                                value={quiz.id}
                                type="hidden"
                            />
                            <Controller
                                control={control}
                                name={`styles.${index}.value`}
                                // defaultValue={
                                //     quiz.styles?.find(s => s.prop === sp.title)
                                //         ?.value ?? ''
                                // }
                                render={({field, fieldState}) => {
                                    return (
                                        <div className="flex flex-row">
                                            <div className="basis-11/12">
                                                <ThemeProvider theme={MUITheme}>
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth={true}>
                                                        <TextField
                                                            {...field}
                                                            error={Boolean(
                                                                fieldState.error,
                                                            )}
                                                            label={sp.label}
                                                            variant="standard"
                                                            size="small"
                                                            inputRef={field.ref}
                                                            helperText={
                                                                fieldState.error
                                                                    ?.message
                                                            }
                                                        />
                                                    </FormControl>
                                                </ThemeProvider>
                                            </div>
                                            {sp.type === 'color' ? (
                                                <div
                                                    className={
                                                        'basis-1/12 w-full h-auto m-auto align-middle'
                                                    }>
                                                    <div
                                                        className={
                                                            'w-5 h-5 rounded'
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                field.value,
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    ))}
            </div>
            <div className="basis-1/4">
                <Controller
                    control={control}
                    name="extra_styles"
                    // defaultValue={quiz?.extra_styles ?? ''}
                    render={({field, fieldState}) => (
                        <div className="mt-2">
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        multiline
                                        rows="4"
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Дополнительные стили"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};
DesignForm.whyDidYouRender = true;
export default React.memo(DesignForm);
