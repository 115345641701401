import React, {useState} from 'react';
import {apiValidationErrorResponse, Quiz} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import Lead2Call from './Integrations/Lead2Call/Lead2Call';
import Comagic from './Integrations/Comagic/Comagic';
import Calltouch from './Integrations/Calltouch/Calltouch';
import SmartCallback from './Integrations/SmartCallback/SmartCallback';
import {toast} from 'react-toastify';
import {UseFormReturn} from 'react-hook-form/dist/types';
import {useUpdateQuizMutation} from '../../../redux/services/quizApi';
import YandexMetrika from './Integrations/YandexMetrika/YandexMetrika';
import VKontakte from './Integrations/VKontakte/VKontakte';
import GoogleAnalytics from './Integrations/GoogleAnalytics/GoogleAnalytics';
import GoogleTagManager from './Integrations/GoogleTagManager/GoogleTagManager';
import TargetMail from './Integrations/TargetMail/TargetMail';
import Jivosite from './Integrations/Jivosite/Jivosite';

type Props = {
    quiz: Quiz;
};

const IntegrationsForm: React.FC<Props> = ({quiz}) => {
    const [updateQuiz] = useUpdateQuizMutation();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [activeIntegration, setActiveIntegration] = useState<JSX.Element>();

    const onSubmit = async (
        data: Partial<Quiz>,
        form: UseFormReturn<Quiz, object>,
    ) => {
        console.log('дата: ', data);
        await updateQuiz({...data, id: quiz.id})
            .unwrap()
            .then(() => {
                toast.success('Интеграция успешно обновлена.');
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<Quiz>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    const buttonClassNames = (used: any) => {
        return (
            'btn-sm text-white mr-1 ' +
            (used
                ? 'bg-green-500 hover:bg-green-600'
                : 'bg-indigo-500 hover:bg-indigo-600')
        );
    };

    return (
        <>
            <ModalBasic
                id="basic-modal"
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                title={modalTitle}>
                {activeIntegration}
            </ModalBasic>

            <div className="flex flex-row">
                <div className="basis-4/4">
                    <h3 className="mt-2 mb-2">Обработка заявок</h3>

                    <button
                        className={buttonClassNames(
                            quiz.lead2call_link || quiz.lead2call_links?.length,
                        )}
                        onClick={() => {
                            setModalTitle('Интеграция Lead2Call');
                            setActiveIntegration(
                                <Lead2Call
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        Lead2Call
                    </button>

                    <button
                        className={buttonClassNames(quiz.comagic_site_id)}
                        onClick={() => {
                            setModalTitle('Интеграция Comagic');
                            setActiveIntegration(
                                <Comagic
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        Comagic
                    </button>

                    <button
                        className={buttonClassNames(quiz.calltouch_site_id)}
                        onClick={() => {
                            setModalTitle('Интеграция CallTouch');
                            setActiveIntegration(
                                <Calltouch
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        CallTouch
                    </button>

                    <button
                        className={buttonClassNames(quiz.scb_links?.length)}
                        onClick={() => {
                            setModalTitle('Интеграция SmartCallback');
                            setActiveIntegration(
                                <SmartCallback
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        SmartCallback
                    </button>

                    <h3 className="mt-4 mb-2">Статистика</h3>

                    <button
                        className={buttonClassNames(quiz.ym_id)}
                        onClick={() => {
                            setModalTitle('Интеграция Яндекс Метрики');
                            setActiveIntegration(
                                <YandexMetrika
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        Яндекс.Метрика
                    </button>

                    <button
                        className={buttonClassNames(
                            quiz.vk_id || quiz.vk_ads_id,
                        )}
                        onClick={() => {
                            setModalTitle('Интеграция ВКонтакте');
                            setActiveIntegration(
                                <VKontakte
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        ВКонтакте
                    </button>

                    <button
                        className={buttonClassNames(quiz.ga_id)}
                        onClick={() => {
                            setModalTitle('Интеграция Google Analytics');
                            setActiveIntegration(
                                <GoogleAnalytics
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        Google Analytics
                    </button>

                    <button
                        className={buttonClassNames(quiz.gtm_id)}
                        onClick={() => {
                            setModalTitle('Интеграция Google Tag Manager');
                            setActiveIntegration(
                                <GoogleTagManager
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        Google Tag Manager
                    </button>

                    <button
                        className={buttonClassNames(quiz.tm_id)}
                        onClick={() => {
                            setModalTitle('Интеграция Target Mail');
                            setActiveIntegration(
                                <TargetMail
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        Target Mail
                    </button>

                    <h3 className="mt-4 mb-2">Связь с клиентами</h3>

                    <button
                        className={buttonClassNames(quiz.jivosite_id)}
                        onClick={() => {
                            setModalTitle('Интеграция с Jivosite');
                            setActiveIntegration(
                                <Jivosite
                                    setModalOpen={setModalOpen}
                                    quiz={quiz}
                                    onSubmit={onSubmit}
                                />,
                            );
                            setModalOpen(true);
                        }}>
                        Jivosite
                    </button>
                </div>
            </div>
        </>
    );
};
export default IntegrationsForm;
