import React, {useState} from 'react';
import {apiValidationErrorResponse, Quiz} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import {nanoid} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {useAddQuizMutation} from '../../../redux/services/quizApi';
import {useNavigate} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import InputField from '../../Elements/InputField';
import MUITheme from '../../Theme/MUITheme';
import {FormControl, TextField, ThemeProvider} from '@mui/material';

type Props = {
    quiz?: Quiz;
};

const NewQuiz: React.FC<Props> = ({quiz}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [addQuiz, result] = useAddQuizMutation();
    const navigate = useNavigate();
    const form = useForm<Quiz>();
    const {control} = form;
    const newQuizId = nanoid(10);

    const onSubmit = async (data: Quiz) => {
        await addQuiz({...data, id: newQuizId})
            .unwrap()
            .then(() => {
                toast.success('Квиз успешно добавлен.');
                navigate('/editQuiz/' + newQuizId);
            })
            .catch((response: apiValidationErrorResponse<Quiz>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    return (
        <div className="m-1.5">
            {/* Start */}
            <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                aria-controls="basic-modal"
                onClick={e => {
                    e.stopPropagation();
                    setModalOpen(true);
                }}>
                <svg
                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                    viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">Добавить квиз</span>
            </button>
            <ModalBasic
                id="basic-modal"
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                title="Новый квиз">
                {/* Modal content */}
                <div className="px-5 pt-4 pb-1">
                    <div className="text-sm">
                        <div className="space-y-2">
                            <Controller
                                control={control}
                                name="title"
                                render={({field, fieldState}) => (
                                    <ThemeProvider theme={MUITheme}>
                                        <FormControl
                                            variant="standard"
                                            fullWidth={true}>
                                            <TextField
                                                {...field}
                                                error={Boolean(
                                                    fieldState.error,
                                                )}
                                                label="Название"
                                                variant="standard"
                                                size="small"
                                                inputRef={field.ref}
                                                helperText={
                                                    fieldState.error?.message ??
                                                    'Название квиза отображается только в панели управления.'
                                                }
                                            />
                                        </FormControl>
                                    </ThemeProvider>
                                )}
                            />
                        </div>
                    </div>
                </div>

                {/* Modal footer */}
                <div className="px-5 py-4">
                    <div className="flex flex-wrap justify-end space-x-2">
                        <button
                            className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                            onClick={e => {
                                e.stopPropagation();
                                setModalOpen(false);
                            }}>
                            Отмена
                        </button>
                        <button
                            className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                            onClick={form.handleSubmit(onSubmit)}>
                            Сохранить приступить к настройке
                        </button>
                    </div>
                </div>
            </ModalBasic>
            {/* End */}
        </div>
    );
};
export default NewQuiz;
