import React, {useEffect, useState} from 'react';
import {apiValidationErrorResponse, Quiz} from '../../../../../redux/types';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {FormControl, TextField, ThemeProvider} from '@mui/material';
import MUITheme from '../../../../Theme/MUITheme';
import {toast} from 'react-toastify';
import {UseFormReturn} from 'react-hook-form/dist/types';

type Props = {
    quiz: Quiz;
    setModalOpen: (open: boolean) => void;
    onSubmit: (
        data: Partial<Quiz>,
        form: UseFormReturn<Quiz, object>,
    ) => Promise<void>;
};

const Comagic: React.FC<Props> = ({quiz, setModalOpen, onSubmit}) => {
    const defaultValues = {
        comagic_site_id: quiz.comagic_site_id ?? '',
        comagic_token: quiz.comagic_token ?? '',
    };

    const form = useForm<Quiz>({
        // @ts-ignore
        defaultValues: defaultValues,
    });
    const {control, clearErrors} = form;

    return (
        <div className="px-5 pt-4 pb-1">
            <div className="flex flex-row">
                <div className="w-1/4 pr-3">
                    <Controller
                        control={control}
                        name="comagic_site_id"
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Comagic site_id"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>
                <div className="w-1/2">
                    <Controller
                        control={control}
                        name="comagic_token"
                        render={({field, fieldState}) => (
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Comagic token"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        )}
                    />
                </div>
            </div>
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={e => {
                            form.clearErrors();
                            form.handleSubmit(data => onSubmit(data, form))(e);
                        }}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Comagic;
