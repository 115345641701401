import React from 'react';
import ErrorPage from '../ErrorPage';
import {useQuiz} from '../../redux/hooks/useQuiz';
import Dashboard from './Dashboard';

const QuizPage = () => {
    const {data: quiz, error} = useQuiz();

    if (error) return <ErrorPage />;
    if (!quiz) return null;

    return <Dashboard quiz={quiz} />;
};

export default QuizPage;
