import React, {useEffect, useState} from 'react';
import '../../css/style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import Header from '../Header';
import {Route, Routes} from 'react-router-dom';
import HomePage from '../../pages/homePage';

import Sidebar from '../Sidebar';
import SignInPage from '../../pages/signInPage';
import {useAuth} from '../../redux/hooks/useAuth';
import GoogleSpreadsheetsPage from '../../pages/googleSpreadsheetsPage';
import FunnelPage from '../../pages/FunnelPage/FunnelPage';
import UsersPage from '../../pages/UsersPage';
import QuizPage from '../../pages/QuizPage';
import EditQuizPage from '../../pages/EditQuizPage';
import ReportsPage from '../../pages/ReportsPage';

const App = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    useEffect(() => {
        setFavicon();
    }, []);

    const {user} = useAuth();

    if (!user) {
        return <SignInPage />;
    }

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />

            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
                <Header
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
                <main>
                    <Routes>
                        <Route
                            path="/search/:searchTerm?"
                            element={<HomePage />}
                        />
                        <Route path="/" element={<HomePage />} />
                        <Route path="quiz/:quizId" element={<QuizPage />} />
                        <Route
                            path="editQuiz/:quizId"
                            element={<EditQuizPage />}
                        />
                        <Route path="funnel/:quizId" element={<FunnelPage />} />
                        <Route path="users/" element={<UsersPage />} />
                        <Route path="reports/" element={<ReportsPage />} />
                        <Route
                            path="google_spreadsheets/"
                            element={<GoogleSpreadsheetsPage />}
                        />
                    </Routes>
                </main>
            </div>
        </div>
    );
};

const setFavicon = () => {
    Array.from(
        document.querySelectorAll(
            'link[rel="icon"], link[rel="shortcut icon"]',
        ),
    ).map(elem =>
        elem.setAttribute(
            'href',
            '/img/favicon/favicon_' + process.env.REACT_APP_ENV_AREA + '.png',
        ),
    );
};

App.whyDidYouRender = true;
export default App;
