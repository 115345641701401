import React, {useState} from 'react';
import {useGetQuizzesQuery} from '../../redux/services/quizApi';
import QuizzesListItem from './QuizzesListItem';
import ActivityIndicator from '../ActivityIndicator';
import PaginationNumeric from '../Elements/PaginationNumeric';

type Props = {
    deferredSearchTerm: string;
};

const QuizzesList: React.FC<Props> = props => {
    const [page, setPage] = useState(1);
    const {
        data: apiResponse,
        isFetching,
        isLoading,
    } = useGetQuizzesQuery(
        {page: page, searchTerm: props.deferredSearchTerm},
        {refetchOnMountOrArgChange: true},
    );

    if (isLoading || isFetching || !apiResponse) return <ActivityIndicator />;

    if (apiResponse.items.length === 0) return <div>Ничего не найдено</div>;

    return (
        <>
            <div className="bg-white shadow-lg rounded-sm border-gray-200 relative">
                <div>
                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                                <tr>
                                    <th />
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div className="font-semibold text-left">
                                            Название
                                        </div>
                                    </th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left" />
                                </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm font-medium divide-y divide-gray-100">
                                {apiResponse.items.map(quiz => (
                                    <QuizzesListItem
                                        quiz={quiz}
                                        key={quiz.id}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <PaginationNumeric
                setPage={setPage}
                currentPage={page}
                pageCount={apiResponse.pageCount}
            />
        </>
    );
};

export default QuizzesList;
