import React, {useState} from 'react';
import {useGetUsersQuery} from '../../../redux/services/userApi';
import ActivityIndicator from '../../../components/ActivityIndicator';
import UsersListItem from './UsersListItem';
import PaginationNumeric from '../../../components/Elements/PaginationNumeric';
import {User} from '../../../redux/types';
import EditUserModal from './EditUserModal';

type Props = {
    deferredSearchTerm: string;
};

const UsersList: React.FC<Props> = props => {
    const [page, setPage] = useState(1);
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const [editableUser, setEditableUser] = useState<User>();

    const {
        data: apiResponse,
        isFetching,
        isLoading,
    } = useGetUsersQuery(
        {page: page, searchTerm: props.deferredSearchTerm},
        {refetchOnMountOrArgChange: true},
    );

    if (isLoading || isFetching || !apiResponse) return <ActivityIndicator />;

    if (apiResponse.items.length === 0)
        return <div>Пользователи не найдены</div>;

    const onClickEditUser = (user: User) => {
        setEditableUser(user);
        setEditUserModalOpen(true);
    };

    return (
        <>
            <EditUserModal
                modalOpen={editUserModalOpen}
                setModalOpen={setEditUserModalOpen}
                user={editableUser}
            />

            <div className="bg-white shadow-lg rounded-sm border-gray-200 relative">
                <div>
                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                                <tr>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div className="font-semibold text-left">
                                            email
                                        </div>
                                    </th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left">
                                        <span className="font-semibold text-left">
                                            Статус
                                        </span>
                                    </th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left" />
                                </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm font-medium divide-y divide-gray-100">
                                {apiResponse.items.map(user => (
                                    <UsersListItem
                                        user={user}
                                        key={user.id}
                                        onClickEditUser={onClickEditUser}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <PaginationNumeric
                setPage={setPage}
                currentPage={page}
                pageCount={apiResponse.pageCount}
            />
        </>
    );
};

export default UsersList;
