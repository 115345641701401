import React from 'react';

type Props = {
    setPage: (page: number) => void;
    currentPage: number;
    pageCount: number;
};

const PaginationNumeric: React.FC<Props> = (props: Props) => {
    if (props.pageCount === 1) return null;

    const renderPageButtons = () => {
        let buttons = [];
        for (let p = 1; p <= props.pageCount; p++) {
            if (props.currentPage === p) {
                buttons.push(
                    <li key={p}>
                        <span className="inline-flex items-center justify-center rounded-l leading-5 px-3.5 py-2 bg-white border border-gray-200 text-indigo-500">
                            {p}
                        </span>
                    </li>,
                );
            } else {
                buttons.push(
                    <li key={p}>
                        <a
                            className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 hover:text-white"
                            onClick={() => props.setPage(p)}
                            href="#">
                            {p}
                        </a>
                    </li>,
                );
            }
        }
        return buttons;
    };

    if (props.pageCount === 0) return null;

    return (
        <div className="flex justify-center my-5">
            <nav className="flex" role="navigation" aria-label="Navigation">
                {props.pageCount - props.currentPage < 1 ? (
                    <div className="mr-2">
                        <a
                            href="#"
                            onClick={() => props.setPage(props.currentPage - 1)}
                            className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 hover:text-white shadow-sm">
                            <span className="sr-only">Назад</span>
                            <wbr />
                            <svg
                                className="h-4 w-4 fill-current"
                                viewBox="0 0 16 16">
                                <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                            </svg>
                        </a>
                    </div>
                ) : (
                    ''
                )}
                <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
                    {renderPageButtons()}
                </ul>
                {props.pageCount - props.currentPage > 0 ? (
                    <div className="mr-2">
                        <a
                            href="#"
                            onClick={() => props.setPage(props.currentPage + 1)}
                            className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 hover:text-white shadow-sm">
                            <span className="sr-only">Вперед</span>
                            <wbr />
                            <svg
                                className="h-4 w-4 fill-current"
                                viewBox="0 0 16 16">
                                <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                            </svg>
                        </a>
                    </div>
                ) : (
                    ''
                )}
            </nav>
        </div>
    );
};

export default PaginationNumeric;
