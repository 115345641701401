import React from 'react';
import {Control, Controller} from 'react-hook-form';
import {Delete, Settings} from '@mui/icons-material';
import {FormControl, TextField, ThemeProvider} from '@mui/material';
import {Quiz, ScbLink} from '../../../../../redux/types';
import MUITheme from '../../../../Theme/MUITheme';

type Props = {
    remove: (index: number) => void;
    index: number;
    field: ScbLink;
    control: Control<Quiz>;
    quiz: Quiz;
};

const LinkBlock: React.FC<Props> = ({remove, index, field, control, quiz}) => {
    const deleteLink = () => {
        window.confirm('Уверены, что хотите удалить эту ссылку?') &&
            remove(index);
    };

    return (
        <div className="flex flex-row" key={field.id}>
            <div className="basis-2/12 mb-2 pr-1">
                <Controller
                    control={control}
                    name={`scb_links.${index}.utm_source`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="utm_source"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>
            <div className="basis-2/12 mb-2 pr-1">
                <Controller
                    control={control}
                    name={`scb_links.${index}.client_token`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="client_token"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>
            <div className="basis-2/12 mb-2 pr-1">
                <Controller
                    control={control}
                    name={`scb_links.${index}.signature`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="signature"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>
            <div className="basis-2/12 mb-2 pr-1">
                <Controller
                    control={control}
                    name={`scb_links.${index}.api_token`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="api_token"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>
            <div className="basis-1/12 mb-2 pr-1">
                <Controller
                    control={control}
                    name={`scb_links.${index}.domen_id`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="domen_id"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            <div className="basis-2/12 mb-2 pr-1">
                <Controller
                    control={control}
                    name={`scb_links.${index}.link`}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="Ссылка"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            <div className="basis-1/12 pl-1 pt-2">
                <button className="align-middle" onClick={deleteLink}>
                    <Delete />
                </button>
            </div>
        </div>
    );
};

export default LinkBlock;
