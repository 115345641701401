import {createApi, retry} from '@reduxjs/toolkit/query/react';
import {baseQueryWithReauth} from './baseQueryWithReauth';
import {
    FunnelData,
    FunnelFilters,
    LeadReportData,
    LeadReportFilters,
} from '../types';
import {UTM_LABELS} from '../../constants';

export type FunnelQuery = {
    id: string;
    filters: FunnelFilters;
};

export type LeadReportQuery = {
    filters: LeadReportFilters;
};

export const statApi = createApi({
    reducerPath: 'statApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getFunnelData: builder.query<FunnelData, FunnelQuery>({
            query: ({id, filters}) => {
                let str = `admin/stat/funnel?id=${id}&debug=1`;
                if (filters.dateRange?.from)
                    str += `&dateFrom=${filters.dateRange.from}`;
                if (filters.dateRange?.to)
                    str += `&dateTo=${filters.dateRange.to}`;

                UTM_LABELS.map(label => {
                    if (filters[label])
                        str += '&' + label + '=' + filters[label];
                });

                return str;
            },
        }),
        getLeadReportData: builder.query<LeadReportData, LeadReportQuery>({
            query: ({filters}) => {
                return `admin/stat/lead-report?dateFrom=${filters.dateRange.from}&dateTo=${filters.dateRange.to}&debug=1`;
            },
        }),
    }),
});

export const {useGetFunnelDataQuery, useGetLeadReportDataQuery} = statApi;
