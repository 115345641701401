import React from 'react';
import {useGoogleLogin} from '@react-oauth/google';
import {
    useGetSpreadsheetEmailQuery,
    useConnectSpreadsheetsAccountMutation,
} from '../redux/services/googleAuthApi';
import ActivityIndicator from '../components/ActivityIndicator';
import {allScopesGranted} from '../utils';
import Breadcrumbs from '../components/Elements/Breadcrumbs';

function GoogleSpreadsheetsPage() {
    const [connectSpreadsheetsAccountMutation] =
        useConnectSpreadsheetsAccountMutation();
    const {
        data: email,
        isLoading,
        isFetching,
    } = useGetSpreadsheetEmailQuery('');

    const needScopes = [
        'email',
        'profile',
        'https://www.googleapis.com/auth/spreadsheets',
        'https://www.googleapis.com/auth/drive.file',
        'https://www.googleapis.com/auth/drive.readonly',
        'openid',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
    ];

    const connect = useGoogleLogin({
        onSuccess: codeResponse => {
            console.log(codeResponse);
            const scopesOk = allScopesGranted(codeResponse.scope, needScopes);

            if (!scopesOk) alert('Необходимо выбрать все разрешения!');
            else connectSpreadsheetsAccountMutation(codeResponse);
        },
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly',
    });

    if (isLoading || isFetching) return <ActivityIndicator />;
    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs breadcrumbs={[{title: 'Google таблицы'}]} />
                </div>
            </div>
            <div>
                Лиды по всем вашим квизам будут выгружаться в таблицы на Google
                Drive подключенного аккаунта.
            </div>
            {email === false
                ? 'Аккаунт не подключен. Выгрузка статистики производиться не будет.'
                : 'Подключен аккаунт ' + email + '.'}
            <div>
                <button
                    onClick={connect}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                    Подключить {email && 'другой'} аккаунт Google
                </button>
            </div>
        </div>
    );
}

export default GoogleSpreadsheetsPage;
