import React, {useEffect} from 'react';
import ImageUploading, {ImageListType} from 'react-images-uploading';
import {FieldError} from 'react-hook-form';
import {Add, Close, CloseOutlined, PlusOne} from '@mui/icons-material';
import {nanoid} from '@reduxjs/toolkit';

type Props = {
    name: string;
    label?: string;
    placeholder?: string | number;
    hint?: string;
    value?: any[];
    required?: boolean;
    onChange: any;
    error?: FieldError;
    ownerId: string;
};
const ImagesForm: React.FC<Props> = React.forwardRef((props, ref) => {
    const [images, setImages] = React.useState(props.value ?? []);
    const maxNumber = 10;

    useEffect(() => {
        setImages(props.value ?? []);
    }, [props.value]);

    const onImagesChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined,
    ) => {
        imageList.map(image => {
            image.owner_id = props.ownerId;
            if (image.id === undefined) image.id = nanoid(10);
        });
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList as never[]);
        props.onChange(imageList);
    };

    return (
        <div className="App">
            <ImageUploading
                multiple
                value={images}
                onChange={onImagesChange}
                maxNumber={maxNumber}>
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <div className="border-2 border-dashed rounded p-2 flex flex-wrap">
                        {imageList.length < maxNumber && (
                            <div className="w-[100px] text-xs h-20 float-left p-1">
                                <div
                                    className="w-full h-full bg-indigo-300 hover:bg-indigo-400 text-white rounded p-1 cursor-pointer text-center text-sm leading-4"
                                    onClick={onImageUpload}
                                    style={
                                        isDragging
                                            ? {background: 'red'}
                                            : undefined
                                    }
                                    {...dragProps}>
                                    <Add />
                                    <div className="m-1 text-xs">
                                        добавить изображение
                                    </div>
                                </div>
                            </div>
                        )}

                        {/*<button onClick={onImageRemoveAll}>*/}
                        {/*    Удалить все картинки*/}
                        {/*</button>*/}
                        {imageList.map((image, index) => (
                            <div
                                key={index}
                                className="relative h-20 float-left p-1">
                                <img
                                    src={image.dataURL}
                                    alt=""
                                    className="rounded max-h-full"
                                />
                                <div className=" absolute right-1 top-1">
                                    {/*<button*/}
                                    {/*    onClick={() => onImageUpdate(index)}>*/}
                                    {/*    Update*/}
                                    {/*</button>*/}
                                    <button
                                        onClick={() => onImageRemove(index)}
                                        title="Удалить изображение"
                                        className="text-white shadow shadow-black rounded bg-opacity-40 bg-gray-200">
                                        <CloseOutlined className=" text-white" />
                                    </button>
                                </div>
                            </div>
                        ))}

                        {imageList.length ? (
                            <div className="w-[100px] h-20 float-left p-1">
                                <div
                                    className="w-full h-full bg-gray-300 hover:bg-red-400 text-white rounded p-1 cursor-pointer text-center text-sm leading-4"
                                    onClick={() =>
                                        window.confirm(
                                            'Уверены, что хотите удалить все изображения?',
                                        ) && onImageRemoveAll()
                                    }>
                                    <Close />
                                    <div className="m-1 text-xs">
                                        удалить все изображения
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="clear-both" />
                    </div>
                )}
            </ImageUploading>
        </div>
    );
});

export default ImagesForm;
