import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from '../types';
import {RootState} from '../store';

type AuthState = {
    user: User | null;
    token: string | null;
};

const slice = createSlice({
    name: 'auth',
    initialState: {user: null, token: null} as AuthState,
    reducers: {
        setCredentials: (
            state,
            {
                payload: {user, token},
            }: PayloadAction<{user?: User; token: string}>,
        ) => {
            user && (state.user = user);
            state.token = token;
        },
        logout: state => {
            state.user = null;
            state.token = null;
        },
    },
});

export const {setCredentials, logout} = slice.actions;

export const authReducer = slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
