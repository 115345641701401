import React, {useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {
    apiValidationErrorResponse,
    Question,
    Connection,
    ConnectionConditionTypeEnum,
    ConnectionConditionSignEnum,
} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import {useUpdateConnectionMutation} from '../../../redux/services/quizApi';
import ConnectionConditionForm from './ConnectionConditionForm';

type Props = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    connection: Connection;
    questions: Question[];
    onElementUnselect: () => void;
};
const EditConditionsModal: React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    connection,
    questions,
    onElementUnselect,
}) => {
    const [updateConnection] = useUpdateConnectionMutation();
    const defaultValues = {
        // title: question.title ?? '',
    };
    const form = useForm<Connection>({
        defaultValues: defaultValues,
    });
    useEffect(() => {
        form.reset(defaultValues);

        remove();
        connection.connectionConditions &&
            append(connection.connectionConditions);
    }, [connection]);

    const {control} = form;
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'connectionConditions',
        keyName: 'key',
    });

    const addConditionField = () => {
        const newCondition = {
            id: nanoid(10),
            connection_id: connection.id,
            answer_option_id: '',
            question_id: '',
            type: 'answer_option' as ConnectionConditionTypeEnum,
            sign: '=' as ConnectionConditionSignEnum,
            value: 0,
        };
        append(newCondition);
    };

    const onSubmit = async (data: Connection) => {
        console.log({...data, id: connection.id});
        await updateConnection({...data, id: connection.id})
            .unwrap()
            .then(() => {
                toast.success('Условия успешно обновлены.');
                setModalOpen(false);
                onElementUnselect();
            })
            .catch((response: apiValidationErrorResponse<Connection>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    const answerOptionsBlock = () => {
        return (
            <div className="mt-4">
                {fields.map((field, index, reset) => (
                    <ConnectionConditionForm
                        key={index}
                        remove={remove}
                        index={index}
                        field={field}
                        control={control}
                        questions={questions}
                        watch={form.watch}
                    />
                    // <div key={index}>Условие</div>
                ))}
                <div className="w-full text-right">
                    <button className="btn-xs" onClick={addConditionField}>
                        + условие
                    </button>
                </div>
            </div>
        );
    };

    return (
        <ModalBasic
            id="basic-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title="Управление условиями перехода">
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
                <div className={'text-sm'}>
                    {fields.length === 0
                        ? 'Условия для связи не настроены, переход по ней будет осуществлен после проверки условий в других связях.'
                        : 'Переход будет осуществлен, если будут соблюдены ВСЕ условия.'}
                </div>
                {answerOptionsBlock()}
            </div>

            {/* Modal footer */}
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={form.handleSubmit(onSubmit)}>
                        Сохранить
                    </button>
                </div>
            </div>
        </ModalBasic>
    );
};
export default EditConditionsModal;
