import React, {useEffect, useState} from 'react';
import {Quiz} from '../../../../../redux/types';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {nanoid} from '@reduxjs/toolkit';
import LinkBlock from './LinkBlock';
import {UseFormReturn} from 'react-hook-form/dist/types';

type Props = {
    quiz: Quiz;
    setModalOpen: (open: boolean) => void;
    onSubmit: (
        data: Partial<Quiz>,
        form: UseFormReturn<Quiz, object>,
    ) => Promise<void>;
};

const SmartCallback: React.FC<Props> = ({quiz, setModalOpen, onSubmit}) => {
    const form = useForm<Quiz>();
    const {control} = form;

    useEffect(() => {
        remove();
        quiz.scb_links && append(quiz.scb_links);
    }, [quiz]);

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'scb_links',
        keyName: 'key',
    });

    const addLinkField = () => {
        const newLink = {
            id: nanoid(10),
            quiz_id: quiz.id,
            utm_source: '',
            link: '',
            client_token: '',
            api_token: '',
            signature: '',
            domen_id: 0,
        };
        append(newLink);
    };

    const linksBlock = () => {
        return (
            <div className="">
                {fields.map((field, index, reset) => (
                    <LinkBlock
                        key={index}
                        remove={remove}
                        index={index}
                        field={field}
                        control={control}
                        quiz={quiz}
                    />
                ))}
                <div className="w-full text-right">
                    <button className="btn-xs" onClick={addLinkField}>
                        + ссылка
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="px-5 pt-4 pb-1">
            <div>{linksBlock()}</div>
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={e => {
                            form.clearErrors();
                            form.handleSubmit(data => onSubmit(data, form))(e);
                        }}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};
export default SmartCallback;
