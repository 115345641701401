import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useGetQuizQuery} from '../../redux/services/quizApi';
import Filter from './Filter';
import {FunnelFilters} from '../../redux/types';
import {useGetFunnelDataQuery} from '../../redux/services/statApi';
import ActivityIndicator from '../../components/ActivityIndicator';
import Table from './Table';
import Breadcrumbs from '../../components/Elements/Breadcrumbs';

function FunnelPage() {
    const [filters, setFilters] = useState<FunnelFilters>({
        dateRange: {from: null, to: null},
        utm_campaign: null,
        utm_content: null,
        utm_medium: null,
        utm_source: null,
        utm_term: null,
    });
    const {quizId} = useParams<{quizId: string}>();

    const {
        data: quiz,
        isFetching: isQuizFetching,
        isLoading: isQuizLoading,
        // @ts-ignore
    } = useGetQuizQuery(quizId);

    const {
        data: funnelData,
        isFetching: isFunnelFetching,
        isLoading: isFunnelLoading,
    } = useGetFunnelDataQuery({
        // @ts-ignore
        id: quizId,
        filters: filters,
    });

    if (!quiz) return null;

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs
                        breadcrumbs={[
                            {title: 'Квизы', url: '/'},
                            {title: quiz.title, url: '/quiz/' + quiz.id},
                            {title: 'Воронка'},
                        ]}
                    />
                </div>
            </div>
            <Filter onSubmit={setFilters} />

            {isQuizLoading ||
            isFunnelFetching ||
            isFunnelLoading ||
            isQuizFetching ? (
                <ActivityIndicator />
            ) : (
                <Table filters={filters} data={funnelData} />
            )}
        </div>
    );
}

export default FunnelPage;
