import React from 'react';

type Props = {
    isActive: boolean;
    title: string;
    id: string;
};

const TabTitle: React.FC<Props> = ({isActive, title, id}) => {
    const classes = isActive
        ? 'block pb-3 text-indigo-500 whitespace-nowrap border-b-2 border-indigo-500'
        : 'block pb-3 text-gray-500 hover:text-gray-600 whitespace-nowrap';
    return (
        <li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
            <a className={classes} href={'#' + id}>
                {title}
            </a>
        </li>
    );
};

export default TabTitle;
