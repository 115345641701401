import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {FormControl, TextField, ThemeProvider} from '@mui/material';
import {apiValidationErrorResponse, Form, Page} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import MUITheme from '../../Theme/MUITheme';
import {useUpdatePageMutation} from '../../../redux/services/quizApi';
import ImagesForm from '../../Elements/ImagesForm';
import ImageForm from '../../Elements/ImageForm';

type Props = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    pageObj: Page;
};
const EditPageModal: React.FC<Props> = ({modalOpen, setModalOpen, pageObj}) => {
    const [updatePageObj] = useUpdatePageMutation();
    const defaultValues = {
        title: pageObj.title ?? '',
        description: pageObj.description ?? '',
        button_text: pageObj.button_text ?? '',
        images: pageObj.images ?? [],
    };
    const page = useForm<Page>({
        defaultValues: defaultValues,
    });

    useEffect(() => {
        page.reset(defaultValues);
    }, [pageObj]);

    const {control} = page;

    const onSubmit = async (data: Page) => {
        await updatePageObj({...data, id: pageObj.id})
            .unwrap()
            .then(() => {
                toast.success(
                    pageObj.is_modal
                        ? 'Модальное окно обновлено'
                        : 'Страница обновлена',
                );
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<Page>) => {
                response.data.map(err =>
                    page.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
                toast.error('Исправьте ошибки и попробуйте еще раз.');
            });
    };

    return (
        <ModalBasic
            id="basic-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title={
                'Редактирование ' +
                (pageObj.is_modal ? 'модального окна' : 'страницы')
            }>
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
                <Controller
                    control={control}
                    name="title"
                    // defaultValue={question?.title}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    multiline={true}
                                    rows={2}
                                    error={Boolean(fieldState.error)}
                                    label="Заголок"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />

                <Controller
                    control={control}
                    name="description"
                    render={({field, fieldState}) => (
                        <div className="mt-2">
                            <ThemeProvider theme={MUITheme}>
                                <FormControl
                                    variant="standard"
                                    fullWidth={true}>
                                    <TextField
                                        multiline
                                        rows="4"
                                        {...field}
                                        error={Boolean(fieldState.error)}
                                        label="Описание"
                                        variant="standard"
                                        size="small"
                                        inputRef={field.ref}
                                        helperText={fieldState.error?.message}
                                    />
                                </FormControl>
                            </ThemeProvider>
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="images"
                    // defaultValue={question?.images}
                    defaultValue={pageObj?.images}
                    render={({field, fieldState}) => (
                        <div className="mt-5">
                            <ImageForm
                                ownerId={pageObj.id}
                                {...field}
                                {...fieldState}
                            />
                        </div>
                    )}
                />

                <Controller
                    control={control}
                    name="button_text"
                    // defaultValue={question?.title}
                    render={({field, fieldState}) => (
                        <ThemeProvider theme={MUITheme}>
                            <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                    {...field}
                                    error={Boolean(fieldState.error)}
                                    label="Текст на кнопке"
                                    variant="standard"
                                    size="small"
                                    inputRef={field.ref}
                                    helperText={fieldState.error?.message}
                                />
                            </FormControl>
                        </ThemeProvider>
                    )}
                />
            </div>

            {/* Modal footer */}
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={page.handleSubmit(onSubmit)}>
                        Сохранить
                    </button>
                </div>
            </div>
        </ModalBasic>
    );
};
export default EditPageModal;
