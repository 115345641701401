import React, {useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {Controller, useForm} from 'react-hook-form';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
} from '@mui/material';
import {
    apiValidationErrorResponse,
    Question,
    Connection,
    Quiz,
} from '../../../redux/types';
import ModalBasic from '../../Elements/ModalBasic';
import MUITheme from '../../Theme/MUITheme';
import {ANSWER_TYPES} from '../../../constants';
import {useAddQuestionMutation} from '../../../redux/services/quizApi';

type Props = {
    quiz: Quiz;
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    createConnections: (id: string) => Connection[];
};
const NewQuestionModal: React.FC<Props> = ({
    quiz,
    modalOpen,
    setModalOpen,
    createConnections,
}) => {
    const [addQuestion, result] = useAddQuestionMutation();
    const form = useForm<Question>({
        defaultValues: {title: '', type: 'radiobutton'},
    });
    const {control} = form;
    const newQuestionId = nanoid(10);

    const onSubmit = async (data: Question) => {
        const newQuestionData = {
            ...data,
            id: newQuestionId,
            quiz_id: quiz.id,
            connections: createConnections(newQuestionId),
        };

        await addQuestion(newQuestionData)
            .unwrap()
            .then(() => {
                toast.success('Вопрос успешно добавлен.');
                form.reset();
                setModalOpen(false);
            })
            .catch((response: apiValidationErrorResponse<Question>) => {
                response.data.map(err =>
                    form.setError(err.field, {
                        type: 'manual',
                        message: err.message,
                    }),
                );
            });
    };

    return (
        <ModalBasic
            id="basic-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            title="Новый вопрос">
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
                <div className="text-sm">
                    <div className="py-2">
                        <Controller
                            control={control}
                            name="title"
                            defaultValue={''}
                            render={({field, fieldState}) => (
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth={true}>
                                        <TextField
                                            {...field}
                                            error={Boolean(fieldState.error)}
                                            label="Текст вопроса"
                                            variant="standard"
                                            size="small"
                                            inputRef={field.ref}
                                            helperText={
                                                fieldState.error?.message
                                            }
                                        />
                                    </FormControl>
                                </ThemeProvider>
                            )}
                        />
                    </div>
                    <div className="py-2">
                        <Controller
                            control={control}
                            name="type"
                            defaultValue="radiobutton"
                            render={({field, fieldState}) => (
                                <ThemeProvider theme={MUITheme}>
                                    <FormControl variant="standard">
                                        <InputLabel>Тип вопроса</InputLabel>
                                        <Select {...field} size="small">
                                            {ANSWER_TYPES.map(type => (
                                                <MenuItem
                                                    key={type.id}
                                                    value={type.id}>
                                                    {type.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            После создания вопроса изменить тип
                                            будет нельзя
                                        </FormHelperText>
                                    </FormControl>
                                </ThemeProvider>
                            )}
                        />
                    </div>
                </div>
            </div>

            {/* Modal footer */}
            <div className="px-5 py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                    <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={e => {
                            e.stopPropagation();
                            setModalOpen(false);
                        }}>
                        Отмена
                    </button>
                    <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={form.handleSubmit(onSubmit)}>
                        Сохранить
                    </button>
                </div>
            </div>
        </ModalBasic>
    );
};
export default NewQuestionModal;
