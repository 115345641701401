import React, {useEffect, useRef, useState} from 'react';
import cytoscape, {EventHandler} from 'cytoscape';
import dagre from 'cytoscape-dagre';
import {
    Form,
    Question,
    Quiz,
    Connection,
    Result,
    Page,
} from '../../../../redux/types';
import {nanoid} from '@reduxjs/toolkit';
import {cyOptions, generateElements} from './GraphFunctions';
import {useLocation} from 'react-router-dom';

cytoscape.use(dagre);

type Props = {
    quiz: Quiz;
    questions: Question[];
    results: Result[];
    connections: Connection[];
    forms: Form[];
    pages: Page[];
    onElementSelect: EventHandler;
    onElementUnselect: () => void;
    bindingMode: boolean;
    setBindingMode: (mode: boolean) => void;
    activeElementId?: string;
    saveConnection: (connection: Connection) => void;
};

const Graph: React.FC<Props> = props => {
    const container = useRef(null);
    const graph = React.useRef<cytoscape.Core>();
    const location = useLocation();

    const initCy = () => {
        graph.current = cytoscape({
            container: container.current, // container to render in
            elements: generateElements(
                props.quiz,
                props.questions,
                props.results,
                props.connections,
                props.forms,
                props.pages,
            ),
            ...cyOptions,
        });

        // @ts-ignore
        graph.current.layout({name: 'dagre', rankDir: 'LR'}).run();

        props.activeElementId &&
            graph.current?.$('#' + props.activeElementId).select();
        graph.current.$('node, edge').on('select', props.onElementSelect);
        graph.current.$('node, edge').on('unselect', props.onElementUnselect);
    };

    useEffect(() => {
        //todo разобраться с костылем setTimeout
        location.hash &&
            location.hash.substring(1) === 'graph' &&
            setTimeout(initCy, 0);
    }, [
        props.questions,
        props.results,
        props.connections,
        props.forms,
        props.pages,
        props.quiz.first_element_id,
        location.hash,
    ]);

    //@ts-ignore
    useEffect(() => {
        if (props.bindingMode) {
            graph.current?.$('node').on('tap', e => {
                if (e.target?.id() && e.target.id() === props.activeElementId) {
                    alert('Выберите другой элемент.');
                } else {
                    const newConnection = {
                        id: nanoid(10),
                        source_obj_id: props.activeElementId,
                        target_obj_id: e.target.id(),
                        quiz_id: props.quiz.id,
                    };

                    // @ts-ignore
                    props.saveConnection(newConnection);
                    graph.current?.$('node').removeListener('tap');
                    props.onElementUnselect();
                }
            });
            return () => graph.current?.$('node').removeListener('tap');
        }
    }, [props.bindingMode]);

    return (
        <div className="h-96 border-dashed border rounded" ref={container} />
    );
};

export default Graph;
