import React, {useState} from 'react';
import {
    apiValidationErrorResponse,
    Question,
    Quiz,
} from '../../../../redux/types';
import {Add, Edit, FirstPage, Link, Remove} from '@mui/icons-material';
import EditQuestionModal from '../EditQuestionModal';
import {useUpdateQuestionMutation} from '../../../../redux/services/quizApi';
import {toast} from 'react-toastify';

type Props = {
    question: Question;
    quiz: Quiz;
    bindingMode: boolean;
    setBindingMode: (mode: boolean) => void;
    setElementFirst: (id: string) => void;
};

const QuestionMenu: React.FC<Props> = React.forwardRef((props, ref) => {
    const [editQuestionModalOpen, setEditQuestionModalOpen] = useState(false);
    const [updateQuestion] = useUpdateQuestionMutation();

    const softDelete = async () => {
        await updateQuestion({...props.question, status: 'deleted'})
            .unwrap()
            .then(() => {
                toast.success('Вопрос удален');
            })
            .catch((response: apiValidationErrorResponse<Question>) => {
                console.error(response);
            });
    };

    return (
        <div>
            <EditQuestionModal
                question={props.question}
                modalOpen={editQuestionModalOpen}
                setModalOpen={setEditQuestionModalOpen}
            />

            {props.quiz.first_element_id !== props.question.id && (
                <button
                    className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        props.setElementFirst(props.question.id);
                    }}>
                    <FirstPage className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Сделать первым</span>
                </button>
            )}

            {props.bindingMode ? (
                <button
                    className="btn-xs  bg-red-500 hover:bg-red-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        props.setBindingMode(false);
                    }}>
                    <Link className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Отменить связь</span>
                </button>
            ) : (
                <button
                    className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        props.setBindingMode(true);
                    }}>
                    <Link className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Связать с ...</span>
                </button>
            )}

            <button
                className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                aria-controls="basic-modal"
                onClick={e => {
                    e.stopPropagation();
                    setEditQuestionModalOpen(true);
                }}>
                <Edit className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="hidden xs:block ml-2">Редактировать</span>
            </button>

            {props.quiz.first_element_id !== props.question.id && (
                <button
                    className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white mt-2"
                    aria-controls="basic-modal"
                    onClick={e => {
                        e.stopPropagation();
                        if (window.confirm('Удалить вопрос?')) {
                            softDelete();
                        }
                    }}>
                    <Remove className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="hidden xs:block ml-2">Удалить</span>
                </button>
            )}
        </div>
    );
});

export default QuestionMenu;
