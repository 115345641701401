import {createTheme} from '@mui/material';

const theme = createTheme({
    typography: {
        body1: {
            fontSize: '0.9rem',
        },
    },
    palette: {
        type: 'light',
        primary: {
            main: 'rgb(99, 102, 241)',
            light: '#ff173c',
        },
        secondary: {
            main: '#f50057',
        },
        text: {
            secondary: 'rgba(30,41,59,0.4)',
            primary: 'rgba(30,41,59,0.8)',
        },
    },
});

export default theme;
