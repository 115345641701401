import React, {useDeferredValue, useState} from 'react';
import SearchForm from '../../components/Elements/SearchForm';
import UsersList from './UsersList';
import Breadcrumbs from '../../components/Elements/Breadcrumbs';

function UsersPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const deferredSearchTerm = useDeferredValue(searchTerm);

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs breadcrumbs={[{title: 'Пользователи'}]} />
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <SearchForm placeholder="Поиск" setTerm={setSearchTerm} />
                </div>
            </div>

            <UsersList deferredSearchTerm={deferredSearchTerm} />
        </div>
    );
}

export default UsersPage;
