import React from 'react';
import {Quiz} from '../../../redux/types';
import {Block, Filter, FilterAltOutlined, Settings} from '@mui/icons-material';
import Breadcrumbs from '../../../components/Elements/Breadcrumbs';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../redux/hooks/useAuth';
import OperationsButtons from './OperationsButtons';

type Props = {
    quiz: Quiz;
};

const Dashboard: React.FC<Props> = ({quiz}) => {
    const navigate = useNavigate();
    const {user} = useAuth();

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}

                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                    {/* Left: Title */}
                    <div className="mb-4 sm:mb-0">
                        <Breadcrumbs
                            breadcrumbs={[
                                {title: 'Квизы', url: '/'},
                                {title: quiz.title},
                            ]}
                        />
                    </div>

                    {/* Right: Actions */}
                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                        {/* Start */}
                        <button
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                            onClick={() => navigate('/editQuiz/' + quiz.id)}>
                            <Settings
                                className="w-4 h-4 fill-current opacity-50 shrink-0"
                                fontSize="small"
                            />
                            <span className="xs:block ml-2">Настроить</span>
                        </button>
                    </div>
                </div>
                <div>
                    <div>
                        Владелец:{' '}
                        {quiz.owner.id === user?.id
                            ? 'Вы'
                            : quiz.owner.username}
                    </div>
                    <div>
                        Статус: {quiz.status === 'on' ? 'Включен' : 'Выключен'}
                    </div>
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={() => navigate('/funnel/' + quiz.id)}>
                        <FilterAltOutlined
                            className="w-4 h-4 fill-current opacity-50 shrink-0"
                            fontSize="small"
                        />
                        <span className="hidden xs:block ml-2">Воронка</span>
                    </button>

                    {quiz.prevent_changes && (
                        <span title="Внесение изменений в квиз запрещено администратором">
                            <Block
                                fontSize="inherit"
                                className="text-red-500 ml-1"
                            />
                        </span>
                    )}
                    <OperationsButtons quiz={quiz} />
                </div>
            </div>
        </>
    );
};

Dashboard.whyDidYouRender = true;
export default React.memo(Dashboard);
