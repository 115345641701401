import React, {useEffect, useRef} from 'react';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import 'suneditor/dist/css/suneditor.min.css';

type Props = {
    onChange: (data: string) => void;
    value: string;
    name: string;
};

const VisualEditor: React.FC<Props> = ({name, onChange, value}) => {
    const editor = useRef<SunEditorCore>();
    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };

    return (
        <SunEditor
            setContents={value}
            name={name}
            lang="ru"
            getSunEditorInstance={getSunEditorInstance}
            placeholder="Описание"
            onChange={onChange}
            setOptions={{
                buttonList: [
                    [
                        'fontSize',
                        'bold',
                        'underline',
                        'italic',
                        'list',
                        'removeFormat',
                        'lineHeight',
                    ],
                ],
                resizingBar: false,
                fontSize: [8, 10, 12, 14, 16, 18, 20],
                lineHeights: [
                    {text: '1', value: 1},
                    {text: '1.25', value: 1.25},
                    {text: '1.5', value: 1.5},
                    {text: '1.75', value: 1.75},
                    {text: '2', value: 2},
                ],
                defaultStyle: 'font-size: 16px;',
                fontSizeUnit: 'px',
            }}
        />
    );
};

export default VisualEditor;
